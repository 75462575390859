import resolveExpression from '../simpleScript/expressionParser/resolveExpression';
import {parse, simplify, simplifyLostLess} from 'txml';

export default function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            data={}, variableName='', variableUnavailable=false,
            convertionType=''
        } = executionObject;
        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }
        let variableCopy = variableObject;
        let resolvedData = resolveExpression(
            data,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedValue = resolvedData.value;
        variableCopy = resolvedData.variableObject;
        let parsedValue;
        if(convertionType === 'parse'){
            parsedValue = parse(resolvedValue);
        }else if(convertionType === 'simplify'){
            parsedValue = simplify(parse(resolvedValue));
        }else if(convertionType === 'lostlessSimplify'){
            parsedValue = simplifyLostLess(parse(resolvedValue));
        }
        variableCopy[variableName] = parsedValue;
        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy,
            hasError : false,
            errorPayload : undefined
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/general/functionTypeParser/xmlToJsonParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}