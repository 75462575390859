export default function(
    data,
    password,
    encrypt=true
){
    try{
        let parsed;
        if(encrypt){
            parsed = window.CryptoJS.AES.encrypt(data, password).toString();
        }else{
            let bytes = window.CryptoJS.AES.decrypt(data, password);
            parsed = bytes.toString(window.CryptoJS.enc.Utf8);
        }
        return parsed;
    }catch(e){
        console.log('encryptDecrypt error', e)
        return data;
    }
}