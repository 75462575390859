import React from 'react';

export default {
    CodeEditorViewer : function(){ return React.lazy(() => import('../../elementUi/elements/codeEditorViewer'))},
    ChartViewer : function(){ return React.lazy(() => import('../../elementUi/elements/chartViewer'))},
    RateViewer : function(){ return React.lazy(() => import('../../elementUi/elements/rateViewer'))},
    SwitchBuilderViewer : function(){ return React.lazy(() => import('../../elementUi/elements/switchBuilderViewer'))},
    WysiwygViewer : function(){ return React.lazy(() => import('../../elementUi/elements/wysiwygViewer'))},
    StripePaymentElementViewer : function(){ return React.lazy(() => import('../../elementUi/elements/stripePaymentElementViewer'))},
    PdfViewer : function(){ return React.lazy(() => import('../../elementUi/elements/pdfViewer'))},
    DivViewer : function(){ return React.lazy(() => import('../../elementUi/elements/divViewer'))},
    InputViewer : function(){ return React.lazy(() => import('../../elementUi/elements/inputViewer'))},
    RadioCheckboxViewer : function(){ return React.lazy(() => import('../../elementUi/elements/radioCheckboxViewer'))},
    SelectViewer : function(){ return React.lazy(() => import('../../elementUi/elements/SelectViewer'))},
    FileUploadViewer : function(){ return React.lazy(() => import('../../elementUi/elements/fileUploadViewer'))},
    SignatureViewer : function(){ return React.lazy(() => import('../../elementUi/elements/signatureViewer'))},
    ImageViewer : function(){ return React.lazy(() => import('../../elementUi/elements/imageViewer'))},
    EmbedViewer : function(){ return React.lazy(() => import('../../elementUi/elements/embedViewer'))},
    IconViewer : function(){ return React.lazy(() => import('../../elementUi/elements/iconViewer'))},
    RichTextEditorViewer : function(){ return React.lazy(() => import('../../elementUi/elements/richTextEditorViewer'))},
    IframeViewer : function(){ return React.lazy(() => import('../../elementUi/elements/iFrameViewer'))},
    ProgressBarViewer : function(){ return React.lazy(() => import('../../elementUi/elements/progressBarViewer'))},
    TimerViewer : function(){ return React.lazy(() => import('../../elementUi/elements/timerViewer'))},
    MediaPlayerViewer : function(){ return React.lazy(() => import('../../elementUi/elements/mediaPlayerViewer'))},
    TextViewer : function(){ return React.lazy(() => import('../../elementUi/elements/textViewer'))},
    ParagraphViewer : function(){ return React.lazy(() => import('../../elementUi/elements/paragraphViewer'))},
    HeadingViewer : function(){ return React.lazy(() => import('../../elementUi/elements/headingViewer'))},
    LinkViewer : function(){ return React.lazy(() => import('../../elementUi/elements/linkViewer'))},
    FormViewer : function(){ return React.lazy(() => import('../../elementUi/elements/formViewer'))},
    ButtonViewer : function(){ return React.lazy(() => import('../../elementUi/elements/buttonViewer'))},
    ListItemViewer : function(){ return React.lazy(() => import('../../elementUi/elements/listItemViewer'))},
    ListWrapperViewer : function(){ return React.lazy(() => import('../../elementUi/elements/listWrapperViewer'))},
    RecaptchaViewer : function(){ return React.lazy(() => import('../../elementUi/elements/recaptchaViewer'))},
    CanvasViewer : function(){ return React.lazy(() => import('../../elementUi/elements/canvasViewer'))},
    SlideNavViewer : function(){ return React.lazy(() => import('../../elementUi/elements/slideNavViewer'))},
    SliderViewer : function(){ return React.lazy(() => import('../../elementUi/elements/sliderViewer'))},
    SlideSelectorViewer : function(){ return React.lazy(() => import('../../elementUi/elements/slideSelectorViewer'))},
    TabControlViewer : function(){ return React.lazy(() => import('../../elementUi/elements/tabControlViewer'))},
    TabContentWrapperViewer : function(){ return React.lazy(() => import('../../elementUi/elements/tabContentWrapperViewer'))},
    TabWrapperViewer : function(){ return React.lazy(() => import('../../elementUi/elements/tabWrapperViewer'))},
    ModalViewer : function(){ return React.lazy(() => import('../../elementUi/elements/modalViewer'))},
    CalendarViewer : function(){ return React.lazy(() => import('../../elementUi/elements/calendarViewer'))},
    MapboxViewer : function(){ return React.lazy(() => import('../../elementUi/elements/mapboxViewer'))},
    TableViewer : function(){ return React.lazy(() => import('../../elementUi/elements/tableViewer'))},
    TableRowViewer : function(){ return React.lazy(() => import('../../elementUi/elements/tableRowViewer'))},
    TableDataRowWrapperViewer : function(){ return React.lazy(() => import('../../elementUi/elements/tableDataRowWrapperViewer'))},
    TableCellViewer : function(){ return React.lazy(() => import('../../elementUi/elements/tableCellViewer'))},
    TableHeaderCellViewer : function(){ return React.lazy(() => import('../../elementUi/elements/tableHeaderCellViewer'))},
    LinkBlockViewer : function(){ return React.lazy(() => import('../../elementUi/elements/linkBlockViewer'))},
    RichTextViewer : function(){ return React.lazy(() => import('../../elementUi/elements/richTextViewer'))},
    ControlledButtonViewer : function(){ return React.lazy(() => import('../../elementUi/elements/controlledButtonViewer'))},
    PaginationWrapperViewer : function(){ return React.lazy(() => import('../../elementUi/elements/paginationWrapperViewer'))},
    DisplayLimitSelectorViewer : function(){ return React.lazy(() => import('../../elementUi/elements/displayLimitSelectorViewer'))},
    ListPageCountTextViewer : function(){ return React.lazy(() => import('../../elementUi/elements/listPageCountTextViewer'))},
    ListIndexInputViewer : function(){ return React.lazy(() => import('../../elementUi/elements/listIndexInputViewer'))},
    ListDataRowWrapperViewer : function(){ return React.lazy(() => import('../../elementUi/elements/listDataRowWrapperViewer'))},
    CsvToJsonUploaderViewer : function(){ return React.lazy(() => import('../../elementUi/elements/csvToJsonUploaderViewer'))},
    WebComponentViewer : function(){ return React.lazy(() => import('../../elementUi/elements/webComponentViewer'))},
    AutoCompleteViewer : function(){ return React.lazy(() => import('../../elementUi/elements/autoCompleteViewer'))},
}