import simpleScriptParser from '../simpleScript/simpleScriptParser';
import resolveExpression from '../simpleScript/expressionParser/resolveExpression';
import FormData from 'form-data';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            iterationId='', flexApiId='',
            apiType='', apiUrl=[], apiMethod='',
            mode='', bodyContainsFiles=false,
            body={}, params={}, headers={},
            variableName='', variableUnavailable=false
        } = executionObject;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let urlRaw = await simpleScriptParser(
            apiUrl,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap
        )
        let url = urlRaw.value;

        let reqBodyRaw = await resolveExpression(
            body,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let reqBody = reqBodyRaw.value;
        let parametersRaw = await resolveExpression(
            params,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let parameters = parametersRaw.value;
        let headerObjRaw = await resolveExpression(
            headers,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let headerObj = headerObjRaw.value;

        if(
            typeof parameters === 'object' && 
            Object.keys(parameters).length > 0 && 
            typeof url === 'string'
        ){
            let parameterKeys = Object.keys(parameters);
            let splitUrl = url.split('?');
            let finalUrl = url;
            if(splitUrl.length === 1){
                finalUrl += '?';
            }
            for (let i = 0; i < parameterKeys.length; i++){
                let key = parameterKeys[i];
                let value = parameters[key];
                if(
                    i === 0 && 
                    splitUrl.length > 1
                ){
                    finalUrl += '&'
                }
                finalUrl += `${key}=${value}`;
                if(i !== parameterKeys.length - 1){
                    finalUrl += '&';
                }
            }
            url = finalUrl;
        }

        if(
            typeof url === 'string' && 
            (
                !url.toLowerCase().startsWith('http://') &&
                !url.toLowerCase().startsWith('https://')
            )
        ){
            url = `https://${url}`;
        }

        let revisedBody;
        if(
            bodyContainsFiles && 
            typeof reqBody === 'object'
        ){
            let form = new FormData();
            for (let k in reqBody){
                let val = reqBody[k];
                form.append(k, val);
            }
            revisedBody = form;
        }else{
            revisedBody = typeof reqBody === 'object' ? 
            JSON.stringify(reqBody)
            :
            reqBody;
        }
        
        let fetchOptions = {
            method : apiMethod,
            credentials : 'omit',
            mode
        }

        if(apiMethod !== 'GET'){
            fetchOptions['body'] = revisedBody;
        }

        if(
            typeof headerObj === 'object' && 
            Object.keys(headerObj).length > 0
        ){
            fetchOptions['headers'] = headerObj;
        }

        const request = await fetch(
            `${url}`,
            fetchOptions
        )
        .then(res => {
            return res.json()
            .then(responseJSON => {
                return responseJSON;
            })
            .catch(e => {
                return res.text()
                .then(text => {
                    return text;
                })
            })
        })
        .catch((error) => {
            console.log('network request fetch catch block error', error);
            return {
                hasError : true,
                errorPayload : error
            };
        });

        let variableCopy = variableObject;
        variableCopy[variableName] = request;
        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy,
            hasError : request.hasError || request.error ? true : false,
            errorPayload : request.hasError || request.error ? request : undefined
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/networkRequestParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}