export default {
    div : 'DivViewer',
    container : 'DivViewer',
    section : 'DivViewer',
    navbar : 'DivViewer',
    slide : 'DivViewer',
    tab : 'DivViewer',
    tabMenu : 'DivViewer',
    body : 'DivViewer',
    codeEditor : 'CodeEditorViewer',
    chart : 'ChartViewer',
    rate : 'RateViewer',
    switch : 'SwitchBuilderViewer',
    wysiwyg : 'WysiwygViewer',
    stripePaymentElement : 'StripePaymentElementViewer',
    pdfViewer : 'PdfViewer',
    input : 'InputViewer',
    textarea : 'InputViewer',
    checkbox : 'RadioCheckboxViewer',
    radioButton : 'RadioCheckboxViewer',
    select : 'SelectViewer',
    fileUpload : 'FileUploadViewer',
    signaturePad : 'SignatureViewer',
    image : 'ImageViewer',
    embed : 'EmbedViewer',
    icon : 'IconViewer',
    listNav : 'IconViewer',
    richTextEditor : 'RichTextEditorViewer',
    iFrame : 'IframeViewer',
    progressBar : 'ProgressBarViewer',
    timer : 'TimerViewer',
    mediaPlayer : 'MediaPlayerViewer',
    textBlock : 'TextViewer',
    blockQuote : 'TextViewer',
    paragraph : 'ParagraphViewer',
    heading : 'HeadingViewer',
    link : 'LinkViewer',
    form : 'FormViewer',
    button : 'ButtonViewer',
    listItem : 'ListItemViewer',
    list : 'ListWrapperViewer',
    recaptcha : 'RecaptchaViewer',
    canvas : 'CanvasViewer',
    slideNav : 'SlideNavViewer',
    slider : 'SliderViewer',
    slideSelector : 'SlideSelectorViewer',
    tabControl : 'TabControlViewer',
    tabContentWrapper : 'TabContentWrapperViewer',
    tabWrapper : 'TabWrapperViewer',
    modal : 'ModalViewer',
    calendar : 'CalendarViewer',
    mapbox : 'MapboxViewer',
    table : 'TableViewer',
    tableHeaderRow : 'TableRowViewer',
    tableFilterRow : 'TableRowViewer',
    tableDataRow : 'TableRowViewer',
    tableDataRowWrapper : 'TableDataRowWrapperViewer',
    columnFilter : 'TableCellViewer',
    columnCell : 'TableCellViewer',
    columnHeader : 'TableHeaderCellViewer',
    linkBlock : 'LinkBlockViewer',
    richTextViewer : 'RichTextViewer',
    login_button : 'ControlledButtonViewer',
    logout_button : 'ControlledButtonViewer',
    profile_button : 'ControlledButtonViewer',
    paginationWrapper : 'PaginationWrapperViewer',
    displayLimitSelector : 'DisplayLimitSelectorViewer',
    listPageCountText : 'ListPageCountTextViewer',
    listIndexInput : 'ListIndexInputViewer',
    listDataRowWrapper : 'ListDataRowWrapperViewer',
    csvToJsonUploader : 'CsvToJsonUploaderViewer',
    webComponent : 'WebComponentViewer',
    autoComplete : 'AutoCompleteViewer'
}