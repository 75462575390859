import bulkExpressionParser from '../simpleScript/expressionParser/bulkExpressionParser';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        const {
            executionObject={}
        } = functionObj;

        let parsedParameters = await bulkExpressionParser({
            executionObject,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap,
            useAllFields : true
        });

        if(parsedParameters.error){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Failed to resolve function parameters.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let resolvedParams = parsedParameters['accumulator'];
        if(!resolvedParams['csvText']){
            console.log('downloadCsvTextToFile ERROR - CSV test is required');
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'CSV text/string is required',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(!resolvedParams['fileName']){
            console.log('downloadCsvTextToFile ERROR - File name is required');
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'File name is required',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        const downloadLink = document.createElement("a");
        const fileName = resolvedParams['fileName'];
        downloadLink.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(resolvedParams['csvText']);
        downloadLink.download = fileName;
        downloadLink.click();

        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : parsedParameters['variableObject']
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/downloadCsvTextToFileParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        } 
    }
}