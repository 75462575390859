import elementAttributeFunction from "../../components/customComponents/appViewer/utils/functions/attributes/elementAttributeFunction";
import dotNotationMapper from "../data/dotNotationMapper";

export function bulkAttributeParser(obj){
    let {
        usedFunctions={}, appId='', elementId='', listItemPathId='',
        bulkParams={}
    } = obj;
    try{
        let accumulator={};
        let {
            historyPushFunction, setStateFunction, 
            setElementAppData, setParentAppData
        } = usedFunctions;
        let {
            params={}, updatedListIndexes=[], listRowCount=0,
            currentIndex=0, 
            listPaginationMetaData={}
        } = bulkParams;
        let {
            pageIndex=0, pageSize=0
        } = listPaginationMetaData;
        let listItemPath = '';
        params['setStateFunction'] = setStateFunction;
        if(params['otherParameters']){
            params['otherParameters']['inhouz_reserved_parameters_xyzh'] = {
                historyPushFunction,
                appEnvironment : params['environment'],
                setElementAppData,
                setParentAppData
            };
            params['otherParameters']['inWorkerThread'] = true;
            listItemPath = params['otherParameters']['pathToListItem'] || '';
        }
        let baseListItemPath = listItemPath.split('.').slice(0, -1).join('.');
        let updateAll = updatedListIndexes.length === 0, rowCount = pageSize || listRowCount;
        for (let i = 0; i < rowCount; i++){
            let index = currentIndex + i;
            if(
                updateAll ||
                updatedListIndexes.includes(index)
            ){
                let currentDataRowIndex = index > (rowCount - 1) ? 
                index - (pageSize * (pageIndex - 1))
                :
                index;
                params['otherParameters']['listItemIndex'] = index;
                params['otherParameters']['pathToListItem'] = `${baseListItemPath}.${index}`;
                params['otherParameters']['listItemData'] = dotNotationMapper(
                    params['App_Data'],
                    `${baseListItemPath}.${currentDataRowIndex}`,
                    true
                );
                let parsedAttributes = elementAttributeFunction(params);
                accumulator[`${appId}_${elementId}_${listItemPathId}_${index}`] = {
                    trackingKey : Math.random(),
                    properties : params['element']['attributes'] ?
                    {
                        ...params['element']['attributes'],
                        ...parsedAttributes
                    }
                    :
                    parsedAttributes,
                    dynamicAttributes : parsedAttributes
                }
                currentDataRowIndex = null;
                parsedAttributes = null;
            }

            index= null;
        }

        appId = null;
        elementId = null;
        listItemPathId = null;
        updatedListIndexes = null;
        for (let k in bulkParams){
            bulkParams[k] = null;
        }
        bulkParams = null;
        obj = null;
        for (let k in params){
            params[k] = null;
        }
        params=null;
        listItemPath = null;
        listPaginationMetaData = null;
        baseListItemPath = null;
        rowCount = null;
        for (let k in usedFunctions){
            usedFunctions[k] = null;
        }
        historyPushFunction = null;
        
        setElementAppData = null;
        setParentAppData = null;
        usedFunctions = null;
        pageIndex = null;
        pageSize = null;
        updateAll = null;
        return accumulator;
    }catch(e){
        console.log(`bulkAttributeParser.worker.js catch error`, e)
        usedFunctions = null;
        appId = null;
        elementId = null;
        listItemPathId = null;
        for (let k in bulkParams){
            bulkParams[k] = null;
        }
        bulkParams = null;
        return;
    }
}