import React, {Component} from 'react';
import {} from '../../../actions';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import { isEmpty } from 'lodash';
import SubscriptionLogin from './subscriptionLogin';
import SubscriptionTwoFactor from './subscriptionTwoFactor';
import SubscriptionChangePassword from './subscriptionChangePassword';

class SubscriptionLoginBase extends Component{
    
    render(){
        const {
            twoFactorRequired=false, passwordChangeInProgress=false,
            displayMode='', environment='', 
            assetOwnerCompanyId='', assetId=''
        } = this.props;
        let mobileMode = ['tablet', 'mobile'].includes(displayMode);
        return (
            <div
                className='bg-white p-3 shadow-sm'
                style={{
                    width : mobileMode ? '100%' : '50%'
                }}
            >
                {
                    !twoFactorRequired && 
                    !passwordChangeInProgress && 
                    <SubscriptionLogin 
                        // companyId={params['externalCompanyId']}
                        destination='app'
                        displayMode={displayMode}
                        assetOwnerCompanyId={assetOwnerCompanyId}
                        assetId={assetId}
                        environment={environment}
                    />
                }
                {
                    twoFactorRequired && 
                    !passwordChangeInProgress && 
                    <SubscriptionTwoFactor 
                        displayMode={displayMode}
                        assetOwnerCompanyId={assetOwnerCompanyId}
                        assetId={assetId}
                        environment={environment}
                    />
                }
                {
                    !twoFactorRequired &&
                    passwordChangeInProgress && 
                    <SubscriptionChangePassword 
                        // companyId={params['externalCompanyId']}
                        destination='app'
                        displayMode={displayMode}
                        assetOwnerCompanyId={assetOwnerCompanyId}
                        assetId={assetId}
                        environment={environment}
                    />
                }
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
        user : state.SubscriptionAuth.subscriberData,
        subcriberDataLoading: state.SubscriptionAuth.subcriberDataLoading,
        subscriberDataError : state.SubscriptionAuth.subscriberDataError,
        twoFactorRequired : state.SubscriptionAuth.twoFactorRequired,
        passwordChangeInProgress : state.SubscriptionAuth.passwordChangeInProgress
    }
}

export default connect(mapStateToProps, {

})(withRouter(SubscriptionLoginBase));