export default (
    currentValue, method={}
) => {
    try{
        const {
            methodName=''
        } = method;

        if(methodName === 'isFinite'){
            return Number.isFinite(currentValue);
        }else if(methodName === 'isInteger'){
            return Number.isInteger(currentValue);
        }else if(methodName === 'isSafeInteger'){
            return Number.isSafeInteger(currentValue);
        }else if(methodName === 'MAX_VALUE'){
            return Number.MAX_VALUE;
        }else if(methodName === 'MIN_VALUE'){
            return Number.MIN_VALUE;
        }else {
            return currentValue;
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/simpleScript/expressionMethods/methodParser/numberMethodParser catch block error', e);
        return undefined;
    }
}