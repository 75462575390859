import React, {Component} from 'react';
// import { stopReportingRuntimeErrors } from "react-error-overlay";

class ErrorBoundary extends Component {
    state = {
        hasError : false,
        errorMessage : ''
    }
  
    static getDerivedStateFromError(error) {
      return {hasError : true}
    }
  
    componentDidCatch(error, errorInfo) {
      // stopReportingRuntimeErrors();
      this.setState({
        hasError : true,
        errorMessage : error.message
      })
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <h6 className='text-danger'>{this.state.errorMessage}</h6>;
      }
  
      return this.props.children; 
    }
}

export default ErrorBoundary;