export default function (data={}, path='', noStringify=false, fallbackValue){
    try{
        let obj = data;
        let holder = {};
        let split = path && path.split('.');
        let count = split.length;
        // if(count === 1){
        //     if(Array.isArray(data)){
        //         return data[Number(path)];
        //     }else{
        //         return data[path];
        //     }
        // }
        for (let i = 0; i < split.length; i++){
            let field = split[i];
            if(i === 0){
                holder[i] = obj[field];
            }else{
                if(holder[i - 1]){
                    holder[i] = Array.isArray(holder[i - 1]) ? 
                    holder[i - 1][Number(field)] : holder[i - 1][field];
                }
            }
        }
        
        let res = holder[count - 1], returnedRes;
        if(
            typeof res === 'number'
        ){
            returnedRes = res;
        }else if(
            typeof res === 'boolean'
        ){
            returnedRes = res ? true : false;
        }else if(
            typeof res === 'string'
        ){
            returnedRes = res;
        }else if(
            typeof res === 'object'
        ){
            if(noStringify){
                returnedRes = res;
            }else{
                returnedRes = JSON.stringify(res);
            }
        }else{
            returnedRes = '';
        }

        data = null;
        path = null;
        noStringify = null;
        fallbackValue = null;
        obj = null;
        holder = null;
        split = null;
        count = null;
        res = null;
        return returnedRes;
    }catch(e){
        console.log('dot notation mapper error', e);
        if(
            fallbackValue ||
            ['boolean', 'number'].includes(typeof fallbackValue)
        ){
            return fallbackValue;
        }else{
            return null;
        }
    }
}