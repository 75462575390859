import recursiveFunctionStepParser from '../../../../recursiveFunctionStepParser';
import functionResponseObjectExtractor from '../../../../general/functionResponseObjectExtractor';
import resolveExpression from '../../../expressionParser/resolveExpression';

export default async function(
    arrayValue, method={}, variableObject={},
    parameterObject={}, functionMap={}, variableIdMap={},
    setStateFunction
){
    try{
        const {
            methodData={}, 
            iterationId=''
        } = method;
        const {
            initialValue={},
            functionObject={}
        } = methodData;
        const {
            functionSteps=[]
        } = functionObject;

        //normalize workflows into functionSteps
        let normalizedFunctionSteps = [];
        for (let i = 0; i < functionSteps.length; i++){
            let workflow = functionSteps[i];
            let steps = workflow['functionSteps'] || [];
            normalizedFunctionSteps.push(...steps);
        }

        let filteredList = [], functionError = false, errPayload={};
        let upVariableObject = JSON.parse(JSON.stringify(variableObject));
        let initialValueResponse = await resolveExpression(
            initialValue, upVariableObject,
            parameterObject, functionMap, variableIdMap
        );
        let initValue = initialValueResponse.value;
        upVariableObject = JSON.parse(JSON.stringify(initialValueResponse.variableObject));
        let total = initValue;
        for (let i = arrayValue.length - 1; i >= 0; i--){
            let currentValue = arrayValue[i];
            let index = i;
            let arr = arrayValue;
            parameterObject[`currentValue_${iterationId}`] = currentValue;
            parameterObject[`index_${iterationId}`] = index;
            parameterObject[`array_${iterationId}`] = arr;
            parameterObject[`total_${iterationId}`] = total;

            let functionResponse = await recursiveFunctionStepParser(
                normalizedFunctionSteps, 
                upVariableObject, 
                parameterObject, 
                functionMap,
                variableIdMap,
                setStateFunction
            );
            let responseObject = functionResponseObjectExtractor(functionResponse);
            const {
                hasError=false, returnStatement,
                updatedVariableObject={},
                errorPayload={}
            } = responseObject;

            upVariableObject = updatedVariableObject;
            if(hasError){
                functionError = hasError;
                errPayload = errorPayload;
                break;
            }else{
                total = returnStatement;
            }
        }

        return {
            value : total,
            variableObject : upVariableObject,
            hasError : functionError,
            errPayload : errPayload
        }
    }catch(e){
        console.log('/utils/functionParser/simpleScript/expressionMethods/methodParsers/functionBased/reduceRightMethodParser', e);
        return {
            value : undefined,
            variableObject,
            hasError : true,
            errorPayload : e
        };
    }
}