import simpleScriptParser from '../simpleScript/simpleScriptParser';
import getCsrf from '../../cookies/getCsrf';
import config from '../../../config/config';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
    setStateFunction
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            iterationId='', variableName='',
            variableUnavailable=false
        } = executionObject;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        const request = await fetch(
            `${config.devServerProxy}/api/remoteexecution/databaseoperation`, 
        {
            method : 'POST',
            headers:{
                'Accept':"application/json",
                'Content-Type':"application/json",
                'Cache': 'no-cache',
                'x-csrfToken' : getCsrf()
            },
            credentials: 'include',
            body : JSON.stringify({
                executionObject,
                variableObject : JSON.parse(JSON.stringify(variableObject)),
                parameterObject
            })
        })
        .then(res => {
            if(res.status !== 200){
                return {
                    hasError : true,
                    errorPayload : res.json()
                };
            }
            let jsonResponse = res.json();
            if(jsonResponse.error || jsonResponse.hasError){
                return {
                    hasError : true,
                    errorPayload : jsonResponse
                }
            }
            return jsonResponse;
        })
        .catch((error) => {
            console.log('dpOpsParser catch block error', error);
            return {
                hasError : true,
                errorPayload : error
            };
        });

        let variableCopy = JSON.parse(JSON.stringify(variableObject));
        variableCopy[variableName] = request;
        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy,
            hasError : request.hasError || request.error ? true : false,
            errorPayload : request.hasError || request.error ? request : undefined
        }

    }catch(e){
        console.log('/utils/syncFunctionParser/general/functionTypeParser/dbOpsParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}