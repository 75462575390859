import recursiveFunctionStepParser from '../../../../recursiveFunctionStepParser';
import functionResponseObjectExtractor from '../../../../general/functionResponseObjectExtractor';

export default async function(
    arrayValue, method, variableObject={},
    parameterObject={}, functionMap={}, variableIdMap={},
    setStateFunction
){
    try{
        const {
            methodData={},
            iterationId=''
        } = method;
        const {
            functionSteps=[]
        } = methodData;

        //normalize workflows into functionSteps
        let normalizedFunctionSteps = [];
        for (let i = 0; i < functionSteps.length; i++){
            let workflow = functionSteps[i];
            let steps = workflow['functionSteps'] || [];
            normalizedFunctionSteps.push(...steps);
        }

        let functionError = false, errPayload={}, mapArray = [];
        let upVariableObject = JSON.parse(JSON.stringify(variableObject));
        for (let i = 0; i < arrayValue.length; i++){
            let currentValue = arrayValue[i];
            let index = i;
            let arr = arrayValue;
            parameterObject[`currentValue_${iterationId}`] = currentValue;
            parameterObject[`index_${iterationId}`] = index;
            parameterObject[`array_${iterationId}`] = arr;
            
            let functionResponse = await recursiveFunctionStepParser(
                normalizedFunctionSteps, 
                upVariableObject, 
                parameterObject, 
                functionMap,
                variableIdMap,
                setStateFunction
            );
            let responseObject = functionResponseObjectExtractor(functionResponse);
            const {
                hasError=false, updatedVariableObject={},
                returnStatement,
                errorPayload={}
            } = responseObject;

            upVariableObject = updatedVariableObject;
            if(hasError){
                functionError = hasError;
                errPayload = errorPayload;
                mapArray.push(undefined);
            }else{
                mapArray.push(returnStatement);
            }
        }

        return {
            value : mapArray,
            variableObject : upVariableObject,
            hasError : functionError,
            errPayload : errPayload
        }
    }catch(e){
        console.log('/utils/functionParser/simpleScript/expressionMethods/methodParsers/functionBased/mapMethodParser', e);
        return {
            value : undefined,
            variableObject,
            hasError : true,
            errorPayload : e
        };
    }
}