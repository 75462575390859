import resolveExpression from '../simpleScript/expressionParser/resolveExpression';
import { json2csv, csv2json } from 'json-2-csv';

export default function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
){
    try{
        const {
            functionType='',
            executionObject={}
        } = functionObj;
        const {
            data={}, variableName='', variableUnavailable=false
        } = executionObject;
        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }
        let variableCopy = variableObject;
        let resolvedData = resolveExpression(
            data,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedValue = resolvedData.value;
        variableCopy = resolvedData.variableObject;
        let parsedValue;
        if(functionType === 'jsonToCsv'){
            parsedValue = json2csv(resolvedValue);
        }else{
            parsedValue = csv2json(resolvedValue);
        }
        variableCopy[variableName] = parsedValue;
        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy,
            hasError : false,
            errorPayload : undefined
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/general/functionTypeParser/jsonCsvConverter catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}