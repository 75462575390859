import resolveExpression from '../simpleScript/expressionParser/resolveExpression';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            inhouz_reserved_parameters_xyzh={}
        } = parameterObject;
        const {setParentAppData} = inhouz_reserved_parameters_xyzh;
        const {
            data={}
        } = executionObject;

        let variableCopy = variableObject;
        let resolvedData = await resolveExpression(
            data,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedValue = resolvedData.value;
        variableCopy = resolvedData.variableObject;

        if(
            setParentAppData &&
            typeof resolvedValue === 'object' && 
            !Array.isArray(resolvedValue)
        ){
            setParentAppData(resolvedValue);
        }

        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy,
            hasError : false,
            errorPayload : undefined
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/updateParentAppDataParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}