import resolveExpression from '../../expressionParser/resolveExpression';
import {cloneDeep} from 'lodash';
import findMethodParser from './functionBased/findMethodParser';
import filterMethodParser from './functionBased/filterMethodParser';
import forEachMethodParser from './functionBased/forEachMethodParser';
import mapMethodParser from './functionBased/mapMethodParser';
import reduceMethodParser from './functionBased/reduceMethodParser';
import reduceRightMethodParser from './functionBased/reduceRightMethodParser';
import orderByMethodParser from './functionBased/orderByMethodParser';
import spliceMethodParser from './functionBased/spliceMethodParser';

export default function(
    currentValue, method={},
    variableObject={}, parameterObject={},
    functionMap={}, variableIdMap={}, setStateFunction
){
    try{
        const {
            methodName='', methodData={}
        } = method;
        let arrayValue = cloneDeep(currentValue);
        let returnValue, updatedVariableObject = variableObject;
        if(methodName === 'isArray'){
            returnValue = Array.isArray(arrayValue);
        }else if(methodName === 'keys'){
            returnValue = arrayValue.keys();
        }else if(methodName === 'pop'){
            arrayValue.pop();
            returnValue = arrayValue;
        }else if(methodName === 'reverse'){
            arrayValue.reverse();
            returnValue = arrayValue;
        }else if(methodName === 'shift'){
            arrayValue.shift();
            returnValue = arrayValue;
        }else if(methodName === 'sort'){
            arrayValue.sort();
            returnValue = arrayValue;
        }else if(methodName === 'fill'){
            let start = resolveExpression(
                methodData['start'], variableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = start.variableObject;
            let end = resolveExpression(
                methodData['end'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = end.variableObject;
            let fillValue = resolveExpression(
                methodData['value'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = fillValue.variableObject;
            arrayValue.fill(
                fillValue.value,
                start.value || 0,
                end.value || arrayValue.length - 1 
            );
            returnValue = arrayValue;
        }else if(methodName === 'join'){
            let seperator = resolveExpression(
                methodData['seperator'], variableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = seperator.variableObject;
            returnValue = arrayValue.join(
                seperator.value
            )
        }else if(methodName === 'lastIndexOf'){
            let item = resolveExpression(
                methodData['item'], variableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = item.variableObject;
            let start = resolveExpression(
                methodData['start'], variableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = start.variableObject;
            returnValue = arrayValue.lastIndexOf(
                item.value,
                start.value
            )
        }else if(methodName === 'push'){
            let newListItem = resolveExpression(
                methodData['New List Item'], variableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = newListItem.variableObject;
            arrayValue.push(
                newListItem.value
            )
            returnValue = arrayValue;
        }else if (methodName === 'unshift'){
            let response = resolveExpression(
                methodData, variableObject,
                parameterObject, functionMap, variableIdMap
            )
            updatedVariableObject = response.variableObject;
            arrayValue.unshift(
                response.value
            )
            returnValue = arrayValue;
        }else if(methodName === 'find'){
            let response = findMethodParser(
                arrayValue,
                method, variableObject,
                parameterObject, functionMap, variableIdMap,
                setStateFunction
            );
            returnValue = response.value;
            updatedVariableObject = response.variableObject;
        }else if(methodName === 'findIndex'){
            let response = findMethodParser(
                arrayValue,
                method, variableObject,
                parameterObject, functionMap, variableIdMap,
                setStateFunction, true
            );
            returnValue = response.value;
            updatedVariableObject = response.variableObject;
        }else if(methodName === 'filter'){
            let response = filterMethodParser(
                arrayValue,
                method, variableObject,
                parameterObject, functionMap, variableIdMap,
                setStateFunction
            );
            returnValue = response.value;
            updatedVariableObject = response.variableObject;
        }else if(methodName === 'forEach'){
            let response = forEachMethodParser(
                arrayValue,
                method, variableObject,
                parameterObject, functionMap, variableIdMap,
                setStateFunction
            );
            returnValue = response.value;
            updatedVariableObject = response.variableObject;
        }else if(methodName === 'map'){
            let response = mapMethodParser(
                arrayValue,
                method, variableObject,
                parameterObject, functionMap, variableIdMap,
                setStateFunction
            );
            returnValue = response.value;
            updatedVariableObject = response.variableObject;
        }else if(methodName === 'reduce'){
            let response = reduceMethodParser(
                arrayValue,
                method, variableObject,
                parameterObject, functionMap, variableIdMap,
                setStateFunction
            );
            returnValue = response.value;
            updatedVariableObject = response.variableObject;
        }else if(methodName === 'reduceRight'){
            let response = reduceRightMethodParser(
                arrayValue,
                method, variableObject,
                parameterObject, functionMap, variableIdMap,
                setStateFunction
            );
            returnValue = response.value;
            updatedVariableObject = response.variableObject;
        }else if(methodName === 'orderBy'){
            let response = orderByMethodParser(
                arrayValue,
                methodData, variableObject,
                parameterObject, functionMap, variableIdMap,
                setStateFunction
            );
            returnValue = response.value;
            updatedVariableObject = response.variableObject;
        }else if(methodName === 'splice'){
            let response = spliceMethodParser(
                arrayValue,
                methodData, variableObject,
                parameterObject, functionMap, variableIdMap,
                setStateFunction
            );
            returnValue = response.value;
            updatedVariableObject = response.variableObject;
        }

        return {
            value : returnValue,
            variableObject : updatedVariableObject
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/simpleScript/expressionMethods/methodParser/arrayMethodParser catch block error', e);
        return {
            value : undefined,
            variableObject,
            hasError : true,
            errorPayload : e
        };
    }
}