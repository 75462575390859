// import config from "../../../config/config";
// import encryptDecrypt from "../../cryptography/encryptDecrypt";
import functionParser from "../functionParser";
import resolveExpression from "../simpleScript/expressionParser/resolveExpression";
// import getExternalFunction from "../simpleScript/utils/getExternalFunction";
// import moment from 'moment';

export default function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
    setStateFunction
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            externalFunctionId='', functionParameters={}
        } = executionObject;

        let functionData = {};
        if(functionMap[externalFunctionId]){
            functionData = functionMap[externalFunctionId];
        }

        if(
            functionData.error ||
            functionData.hasError ||
            Object.keys(functionData).length === 0
        ){
            return {
                hasReturnStatement : false,
                returnStatement : undefined,
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Failed to load function.'
            }
        }else{
            let updatedVariableObject = variableObject;
            functionMap[externalFunctionId] = functionData;
            let event = parameterObject['event'] || {};
            let parsedParams = {...parameterObject};
            let {parameters=[]} = functionData;
            for (let i = 0; i < parameters.length; i++){
                let parameterObj = parameters[i];
                const {
                    systemGenerated=false, parameterName=''
                } = parameterObj;
                if(
                    !systemGenerated && 
                    functionParameters[parameterName]
                ){
                    let paramScriptObj = functionParameters[parameterName];
                    let parsedParamRes = resolveExpression(
                        paramScriptObj,
                        updatedVariableObject,
                        parameterObject,
                        functionMap,
                        variableIdMap
                    )
                    updatedVariableObject = parsedParamRes.variableObject;
                    parsedParams[parameterName] = parsedParamRes.value;
                }
            }
            
            let paramsCopy = parsedParams;
            functionParser(
                functionData,
                {
                    ...paramsCopy,
                    event
                },
                setStateFunction,
                functionMap
            );
        }

        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableObject,
            hasError : false,
            errorPayload : undefined
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/general/functionTypeParser/eventBasicMethodParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}

const isJson = (obj) => {
    try{
        return JSON.stringify(obj)
    }catch(e){
        return false;
    }
}