import {isEqual} from 'lodash';

export default function (accumulator, newValue, operator=''){
    let exists = accumulator || typeof accumulator === 'number';
    // console.log('accumulator', accumulator, newValue, operator)
    switch (operator){
        case 'add':
            return exists ? accumulator + newValue : newValue;
        case 'subtract':
            return exists ? Number(accumulator) - Number(newValue) : 0 - Number(newValue);
        case 'divide':
            return exists ? Number(accumulator) / Number(newValue) : 0 / Number(newValue);
        case 'multiply':
            return exists ? Number(accumulator) * Number(newValue) : 0 * Number(newValue);
        case 'or':
            return exists ? accumulator || newValue : newValue;
        case 'and':
            return exists ? accumulator && newValue : newValue;
        case 'equals':
            return isEqual(accumulator, newValue);
        case 'not equals':
            return !isEqual(accumulator, newValue);
        case 'greater than':
            return accumulator > newValue;
        case 'greater than or equals':
            return accumulator >= newValue;
        case 'less than':
            return accumulator < newValue;
        case 'less than or equals':
            return accumulator <= newValue;
        default:
            return newValue;
    }
}