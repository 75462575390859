import FingerprintJS from '@fingerprintjs/fingerprintjs';
import config from '../../../config/config';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            variableName='', variableUnavailable=false
        } = executionObject;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        const fpPromise = FingerprintJS.load();
        const fp = await fpPromise;
        const fingerprintResult = await fp.get();
        let parsedFingerprint = JSON.parse(JSON.stringify(fingerprintResult));

        variableObject[variableName] = {
            browserFingerprintId : parsedFingerprint.visitorId,
            browserFingerprint : parsedFingerprint
        };
        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableObject,
            hasError : false,
            errorPayload : undefined
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/getBrowserFingerprintParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}