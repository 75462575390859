import localforage from "localforage";
import { set, setMany } from 'idb-keyval';

export default async function(params={}){
    try{
        let isOldIE = false;
        if(
            navigator && 
            (
                navigator.userAgent.indexOf('MSIE')!==-1
                || navigator.appVersion.indexOf('Trident/') > -1
            )
        ){
            isOldIE = true;
        }

        const {
            key='', data, indexDbName='', storeName='',
            bulkSet=false
        } = params;
        if(isOldIE){
            localforage.config({
                name : indexDbName,
                driver : localforage.INDEXEDDB,
                storeName
            });
            localforage.setItem(key, data);
        }else{
            if(bulkSet){
                await setMany(data);
            }else{
                await set(key, data);
            }
        }
        return;
    }catch(e){
        console.log('/indexedDbSetItem catch block error', e);
        return;
    }
}