import resolveExpression from '../simpleScript/expressionParser/resolveExpression';
// import html2PDF from 'jspdf-html2canvas';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        let html2PDF;
        if(typeof window !== 'undefined'){
            html2PDF = require('jspdf-html2canvas')
        }
        const {
            executionObject={}
        } = functionObj;
        const {
            multiPagePdf={}, fileName={}, format={},
            unit={}, orientation={}, imageType={}, imageQuality={},
            singlePageQuerySelector={}, multiPageClassName={},
            scale={}, setToVariable={}, variableName={},
            watermarkText={}, watermarkPosition={}, watermarkFontSize={},
            watermarkAngle={}, watermarkFontColor={}
        } = executionObject;
        let variableCopy = variableObject;

        let resolvedUnit = await resolveExpression(
            unit,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let unitValue = resolvedUnit.value;
        variableCopy = JSON.parse(JSON.stringify(resolvedUnit.variableObject));

        let resolvedOrientation = await resolveExpression(
            orientation,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let orientationValue = resolvedOrientation.value;
        variableCopy = JSON.parse(JSON.stringify(resolvedOrientation.variableObject));

        let resolvedFormat = await resolveExpression(
            format,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedFormatValue = resolvedFormat.value;
        variableCopy = JSON.parse(JSON.stringify(resolvedFormat.variableObject));

        let resolvedImageType = await resolveExpression(
            imageType,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedImageTypeValue = resolvedImageType.value;
        variableCopy = JSON.parse(JSON.stringify(resolvedImageType.variableObject));

        let resolvedImageQuality = await resolveExpression(
            imageQuality,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedImageQualityValue = resolvedImageQuality.value;
        variableCopy = JSON.parse(JSON.stringify(resolvedImageQuality.variableObject));

        let resolvedFileName = await resolveExpression(
            fileName,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedFileNameValue = resolvedFileName.value;
        variableCopy = JSON.parse(JSON.stringify(resolvedFileName.variableObject));

        let resolvedMultiPagePdf = await resolveExpression(
            multiPagePdf,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedMultiPagePdfValue = resolvedMultiPagePdf.value;
        variableCopy = JSON.parse(JSON.stringify(resolvedMultiPagePdf.variableObject));

        let resolvedSinglePageQuerySelector = await resolveExpression(
            singlePageQuerySelector,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedSinglePageQuerySelectorValue = resolvedSinglePageQuerySelector.value;
        variableCopy = JSON.parse(JSON.stringify(resolvedSinglePageQuerySelector.variableObject));

        let resolvedScale = await resolveExpression(
            scale,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedScaleValue = resolvedScale.value;
        variableCopy = JSON.parse(JSON.stringify(resolvedScale.variableObject));

        let resolvedMultiPageClassName = await resolveExpression(
            multiPageClassName,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedClassNameValue = resolvedMultiPageClassName.value;
        variableCopy = JSON.parse(JSON.stringify(resolvedMultiPageClassName.variableObject));

        let resolvedSetToVariable = await resolveExpression(
            setToVariable,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedSetToVariableValue = resolvedSetToVariable.value;
        variableCopy = JSON.parse(JSON.stringify(resolvedSetToVariable.variableObject));

        let resolvedVariableName = await resolveExpression(
            variableName,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedVariableNameValue = resolvedVariableName.value;
        variableCopy = JSON.parse(JSON.stringify(resolvedVariableName.variableObject));

        let resolvedWatermark = await resolveExpression(
            watermarkText,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedWatermarkValue = resolvedWatermark.value;
        variableCopy = JSON.parse(JSON.stringify(resolvedWatermark.variableObject));

        let resolvedWatermarkPosition  = await resolveExpression(
            watermarkPosition,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedWatermarkPositionValue = resolvedWatermarkPosition.value;
        variableCopy = JSON.parse(JSON.stringify(resolvedWatermarkPosition.variableObject));

        let resolvedWatermarkFontSize = await resolveExpression(
            watermarkFontSize,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedWatermarkFontSizeValue = resolvedWatermarkFontSize.value;
        variableCopy = JSON.parse(JSON.stringify(resolvedWatermarkFontSize.variableObject));

        let resolvedWatermarkAngle = await resolveExpression(
            watermarkAngle,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedWatermarkAngleValue = resolvedWatermarkAngle.value;
        variableCopy = JSON.parse(JSON.stringify(resolvedWatermarkAngle.variableObject));

        let resolvedWatermarkFontColor = await resolveExpression(
            watermarkFontColor,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedWatermarkFontColorValue = resolvedWatermarkFontColor.value;
        variableCopy = JSON.parse(JSON.stringify(resolvedWatermarkFontColor.variableObject));

        let jsPDF = {
            compress : false
        }, options = {};
        if(unitValue){
            jsPDF['unit'] = unitValue;
        }
        if(resolvedFormatValue){
            jsPDF['format'] = resolvedFormatValue;
        }
        if(orientationValue){
            jsPDF['orientation'] = orientationValue;
        }
        if(Object.keys(jsPDF).length > 0){
            options['jsPDF'] = jsPDF;
        }
        if(resolvedImageTypeValue){
            options['imageType'] = resolvedImageTypeValue;
        }
        if(resolvedImageQualityValue){
            options['imageQuality'] = resolvedImageQualityValue;
        }

        // if(resolvedFileNameValue){
        //     options['output'] = resolvedFileNameValue;
        // }
        // if(resolvedWatermarkValue){
        //     options['watermark'] = resolvedWatermarkValue;
        // }

        options['watermark'] = ({ pdf, pageNumber, totalPageNumber }) => {
            // pdf: jsPDF instance
            pdf.setTextColor('#ddd');
            pdf.setFontSize(12)
            pdf.text(`${pageNumber}/${totalPageNumber}`, 5, pdf.internal.pageSize.height - 5);

            if(
                resolvedWatermarkValue && 
                typeof resolvedWatermarkValue === 'string'
            ){
                let position = getPosition(resolvedWatermarkPositionValue, pdf.internal.pageSize);
                let waterMarkOptions = {};
                if(
                    resolvedWatermarkFontSizeValue && 
                    typeof resolvedWatermarkFontSizeValue === 'number'
                ){
                    pdf.setFontSize(resolvedWatermarkFontSizeValue);
                }
                if(
                    resolvedWatermarkFontColorValue && 
                    typeof resolvedWatermarkFontColorValue === 'string'
                ){
                    pdf.setTextColor(resolvedWatermarkFontColorValue);
                }
                if(
                    resolvedWatermarkAngleValue && 
                    typeof resolvedWatermarkAngleValue === 'number'
                ){
                    waterMarkOptions['angle'] = resolvedWatermarkAngleValue;
                }

                pdf.text(
                    resolvedWatermarkValue,
                    position.x,
                    position.y,
                    waterMarkOptions
                );
            }
        }

        options['html2canvas'] = {
            imageTimeout: 15000,
            logging: false,
            useCORS: true,
            scale : typeof resolvedScaleValue === 'number' ? resolvedScaleValue : 3
        }

        options['success'] = (pdf) => {
            if(
                resolvedSetToVariableValue && 
                resolvedVariableNameValue
            ){
                let base64 = pdf.output('datauristring');
                variableCopy[resolvedVariableNameValue] = base64;
            }

            if(resolvedFileNameValue){
                pdf.save(resolvedFileNameValue);
            }

            pdf.close();
        }

        let pages;
        if(resolvedMultiPagePdfValue){
            pages = document.getElementsByClassName(resolvedClassNameValue);
        }else{
            pages = document.querySelector(resolvedSinglePageQuerySelectorValue);
        }

        html2PDF(
            pages,
            options
        );

        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy,
            hasError : false,
            errorPayload : undefined
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/generateBrowserPdfParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}

const getPosition = (position, size) => {
    let x = 0, y = 0;
    if(position === 'center'){
        x = size.width / 2;
        y = size.height / 2;
    }else if(position === 'topCenter'){
        x = size.width / 2;
        y = 10;
    }else if(position === 'bottomCenter'){
        x = size.width / 2;
        y = size.height - 10;
    }else if(position === 'topLeft'){
        x = 10;
        y = 10;
    }else if(position === 'topRight'){
        x = size.width - 10;
        y = 10;
    }else if(position === 'bottomLeft'){
        x = 10;
        y = size.height - 10;
    }else if(position === 'bottomRight'){
        x = size.width - 10;
        y = size.height - 10;
    }else{
        x = size.width / 2;
        y = size.height / 2;
    }

    return {x, y};
}