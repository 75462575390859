import simpleScriptParser from '../simpleScript/simpleScriptParser';
import recursiveFunctionStepParser from '../recursiveFunctionStepParser';
import functionResponseObjectExtractor from '../general/functionResponseObjectExtractor';
import getLoopLimit from '../general/getLoopLimit';

export default function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
    setStateFunction
){
    try{
        let updatedVariables = variableObject;
        const {
            executionObject={}
        } = functionObj;
        const {
            iterationId='', startIndex=[],
            loopDirection='', functionObject={},
            loopOperator, loopLength=[]
        } = executionObject;

        let start = simpleScriptParser(
            startIndex,
            updatedVariables,
            parameterObject,
            functionMap,
            variableIdMap
        );
        updatedVariables = start.variableObject;
        let length = simpleScriptParser(
            loopLength,
            updatedVariables,
            parameterObject,
            functionMap,
            variableIdMap
        );
        updatedVariables = length.variableObject;
        const {
            functionSteps=[]
        } = functionObject;
        let normalizedFunctionSteps = [], responseObject={};
        for (let k = 0; k < functionSteps.length; k++){
            let workflow = functionSteps[k];
            let steps = workflow['functionSteps'] || [];
            normalizedFunctionSteps.push(...steps);
        }

        for (
            let i = start.value;
            getLoopLimit(i, loopOperator, length.value);
            loopDirection === 'asc' ?
            i++ :
            i--
        ){
            parameterObject[`index_${iterationId}`] = i;
            let functionResponse = recursiveFunctionStepParser(
                normalizedFunctionSteps, 
                updatedVariables, 
                parameterObject, 
                functionMap,
                variableIdMap,
                setStateFunction
            );
            responseObject = functionResponseObjectExtractor(functionResponse);
            const {
                hasError=false, updatedVariableObject={},
                breakLoop=false, continueLoop=false
            } = responseObject;
            updatedVariables = updatedVariableObject;
            if(breakLoop || hasError){
                break;
            }else if(continueLoop){
                continue;
            }
        }
        return {
            ...responseObject,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : updatedVariables,
            breakLoop : false,
            continueLoop : false
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/general/functionTypeParser/forLoopParser catch block error', e)
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}