import urlBase64ToUint8Array from '../../data/urlBase64ToUnit8Array';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import config from '../../../config/config';
import indexedDbSetItem from '../../indexedDb/indexedDbSetItem';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            variableName='', variableUnavailable=false
        } = executionObject;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let returnObj = {};
        try{
            if(
                ("serviceWorker" in navigator && "PushManager" in window) ||
                window.navigator.standalone
            ){
                const registration = await navigator.serviceWorker.register("/sw.js");
                const subscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: urlBase64ToUint8Array(config.webPushPublicKey)
                });
                const fpPromise = FingerprintJS.load();
                const fp = await fpPromise;
                const fingerprintResult = await fp.get();
                let fingerprintId = fingerprintResult.visitorId;
                let fingerprintObj = JSON.parse(JSON.stringify(fingerprintResult));

                await indexedDbSetItem({
                    key : 'fingerprintObj',
                    data : {
                        browserFingerprintId : fingerprintId,
                        browserFingerprint : fingerprintObj
                    }
                });
    
                returnObj = {
                    pushNotificationSubscriptionObject : JSON.parse(JSON.stringify(subscription)),
                    browserFingerprintId : fingerprintId,
                    browserFingerprint : fingerprintObj
                }
            }else{
                returnObj = {
                    error : {
                        message : 'PushManager is not available.'
                    }
                }
            }

            variableObject[variableName] = returnObj;
            return {
                hasReturnStatement : false,
                returnStatement : undefined,
                updatedVariableObject : variableObject,
                hasError : false,
                errorPayload : undefined
            }
        }catch(subscriptionError){
            console.log('/browserPushNotificationSubscriptionParser subscription error', subscriptionError);
            returnObj = {
                error : {
                    message : subscriptionError.message
                }
            }
            variableObject[variableName] = returnObj;
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : subscriptionError.stack,
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/browserPushNotificationSubscriptionParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}