import getCsrf from "../cookies/getCsrf";
import fetch from 'node-fetch';

export default async function (url, method, data, headers={}) {
    const request = await fetch(url, {
        method,
        headers:{
            'Accept':"application/json",
            'Content-Type':"application/json",
            'Cache': 'no-cache',
            ...headers,
            'x-csrfToken' : getCsrf()
        },
        credentials: 'include',
        body:JSON.stringify(data)
    })
    .then(async res => {
        if(res.status !== 200){
            let resJson = await res.json();
            return {
                hasError : true,
                errorPayload : (
                    resJson['error'] && 
                    resJson['error']['errorPayload'] ?
                    typeof resJson['error']['errorPayload'] !== 'string' ?
                    JSON.stringify(resJson['error']['errorPayload']) : 
                    resJson['error']['errorPayload']
                    :
                    ''
                ) || '',
                errorMessage : (
                    resJson['error'] && 
                    resJson['error']['message']
                ) || ''
            };
        }
        let jsonResponse = await res.json();
        if(jsonResponse.error || jsonResponse.hasError){
            return {
                hasError : true,
                errorPayload : (
                    jsonResponse['error'] && 
                    jsonResponse['error']['errorPayload'] ?
                    typeof jsonResponse['error']['errorPayload'] !== 'string' ?
                    JSON.stringify(jsonResponse['error']['errorPayload']) : 
                    jsonResponse['error']['errorPayload']
                    :
                    ''
                ) || '',
                errorMessage : (
                    jsonResponse['error'] && 
                    jsonResponse['error']['message']
                ) || ''
            }
        }
        return jsonResponse;
    })
    .catch((error) => {
        return {
            hasError : true,
            errorPayload : error,
            errorMessage : ''
        };
    })

    return request;
}