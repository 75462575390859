import {isObject, isArray} from 'lodash';

export const recursiveSanitizer = (element, sanitizer) => {
    if(isObject(element)){
        for (let k in element){
            recursiveSanitizer(element[k], sanitizer);
        }
    }else if(isArray(element)){
        for (let i = 0; i < element.length; i++){
            recursiveSanitizer(element[i], sanitizer);
        }
    }else{
        element = sanitizer.sanitize(element);
    }

    return element;
}

