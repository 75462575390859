import localforage from "localforage";
import { get, getMany } from 'idb-keyval';

export default async function(params={}){
    try{
        let isOldIE = false;
        if(
            navigator && 
            (
                navigator.userAgent.indexOf('MSIE')!==-1
                || navigator.appVersion.indexOf('Trident/') > -1
            )
        ){
            isOldIE = true;
        }

        const {
            key='', indexDbName='', storeName='', multi=false,
            keys=[]
        } = params;
        if(isOldIE){
            localforage.config({
                name : indexDbName,
                driver : localforage.INDEXEDDB,
                storeName
            });
            return await localforage.getItem(key);
        }else{
            if(multi){
                return await getMany(keys);
            }else{
                return await get(key);
            }
        }
    }catch(e){
        console.log('/indexedDbSetItem catch block error', e);
        return null;
    }
}