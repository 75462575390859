import encryptDecrypt from '../../cryptography/encryptDecrypt';
import config from '../../../config/config';
import moment from 'moment';
import bulkExpressionParser from '../simpleScript/expressionParser/bulkExpressionParser';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            variableName='', 
            variableUnavailable=false, 
        } = executionObject;
        const {
            companyId='', inhouz_reserved_parameters_xyzh={}
        } = parameterObject;
        const {appEnvironment=''} = inhouz_reserved_parameters_xyzh;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let parsedParameters = await bulkExpressionParser({
            executionObject,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap,
            useAllFields : true
        });

        let variableCopy = parsedParameters['variableObject'];
        if(parsedParameters.error){
            variableCopy[variableName] = {
                error : {
                    message : 'Failed to parse function data.'
                }
            }
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Failed to resolve function parameters.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let resolvedParams = parsedParameters['accumulator'];

        let notification = {
            notificationTitle : resolvedParams['notificationTitle'] || '',
            notificationMessage : resolvedParams['notificationMessage'] || '',
            notificationLink : resolvedParams['notificationLink'] || '',
            notificationIconUrl : resolvedParams['notificationIconUrl'] || '',
            pushSubscriptionObject : resolvedParams['pushSubscriptionObject']
        }

        if(
            !notification.pushSubscriptionObject ||
            typeof notification.pushSubscriptionObject !== 'object' ||
            (
                typeof notification.pushSubscriptionObject === 'object' && 
                (Object.keys(notification.pushSubscriptionObject).length === 0)
            )
        ){
            variableCopy[variableName] = {
                error : {
                    message : 'Invalid push notification subscription object.'
                }
            }
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Invalid push notification subscription object.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let payload = {
            notifications : [notification],
            encryptedPayload : encryptDecrypt(JSON.stringify({
                payload : {
                    companyId
                },
                expirationTimestamp : moment().add(5, 'minutes').unix() * 1000
            })),
            environment : appEnvironment
        }

        let response = await fetch(
            `${config.devServerProxy}/api/pushnotificationparser/sendpushnotification`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache'
                },
                credentials : 'omit',
                body : JSON.stringify(payload)
            }
        )
        .then(resp => resp.json())
        .catch((error) => {
            return {
                error : {
                    message : error.message
                }
            }
        });

        variableCopy[variableName] = response;
        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/singleBrowserPushNotificationParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        } 
    }
}