import {
    isEqual
} from 'lodash';

export default function (index=0, operator='', lengthValue=0){
    try{
        if(operator === 'gt'){
            return index > lengthValue;
        }else if(operator === 'gte'){
            return index >= lengthValue;
        }else if(operator === 'lt'){
            return index < lengthValue;
        }else if(operator === 'lte'){
            return index <= lengthValue;
        }else if(operator === 'eq'){
            return isEqual(index, lengthValue);
        }else{
            return true;
        }
    }catch(e){
        console.log('/utils/functionParser/general/getLoopLimits catch block error', e);
        return true;
    }
}