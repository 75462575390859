import parseMappingFunctions from '../../mappingFunction/parseMappingFunctions';
import parseExpressionMethods from '../expressionMethods/parseExpressionMethods';
import scriptObjectParser from '../../scriptObjectParser/scriptObjectParser';
import functionParser from '../../functionParser';
import functionResponseObjectExtractor from '../../general/functionResponseObjectExtractor';
import moment from 'moment';
// const safeEval = require('safe-eval');
import evalWrapper from './evalWrapper';

export default function resolveExpression (
    scriptObject={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
    setStateFunction
){
    try{
        const {
            isVariable=false, variableType='',
            inputText='', mappingFunctions=[],
            expressionMethods=[], inputType='',
            companyId='', externalFunctionId='',
            functionParameters={}, varId=''
        } = scriptObject;
        let value;
        let updatedVariableObject = variableObject;
        if(['object', 'array'].includes(inputType)){
            let parsedObject = scriptObjectParser(
                scriptObject,
                updatedVariableObject,
                parameterObject,
                functionMap,
                variableIdMap
            )
            value = parsedObject.value;
            updatedVariableObject = parsedObject.variableObject;
            parsedObject = null;
        }else{
            if(inputType === 'function'){
                let functionData={};
                if(functionMap[externalFunctionId]){
                    functionData = functionMap[externalFunctionId];
                }

                if(
                    functionData.error ||
                    functionData.hasError ||
                    Object.keys(functionData).length === 0
                ){
                    value = undefined;
                }else{
                    functionMap[externalFunctionId] = functionData;
                    let event = parameterObject['event'] || {};
                    let parsedParams = {...parameterObject};
                    let {parameters=[]} = functionData;
                    for (let i = 0; i < parameters.length; i++){
                        let parameterObj = parameters[i];
                        const {
                            systemGenerated=false, parameterName=''
                        } = parameterObj;
                        if(
                            !systemGenerated && 
                            functionParameters[parameterName] 
                        ){
                            let paramScriptObj = functionParameters[parameterName];
                            let parsedParamRes = resolveExpression(
                                paramScriptObj,
                                updatedVariableObject,
                                parameterObject,
                                functionMap,
                                variableIdMap
                            )
                            updatedVariableObject = parsedParamRes.variableObject;
                            parsedParams[parameterName] = parsedParamRes.value;
                            parsedParamRes = null;
                        }
                    }
                    let paramsCopy = parsedParams;
                    let functionResponse = functionParser(
                        functionData,
                        {
                            ...paramsCopy,
                            event
                        },
                        setStateFunction,
                        functionMap
                    );
                    let extractedResponseObj = functionResponseObjectExtractor(functionResponse);
                    value = extractedResponseObj.hasError || 
                    !extractedResponseObj.hasReturnStatement ? 
                    undefined : extractedResponseObj.returnStatement;
                    functionResponse = null;
                    extractedResponseObj = null;
                }
            }else if(inputType === 'script'){
                // let companyId = parameterObject['companyId'] && parameterObject['companyId'].slice();
                // let functionId = parameterObject['functionId'] && parameterObject['functionId'].slice();
                // let inWorkerThread = parameterObject['inWorkerThread'] ? true : false;
                // let VARIABLE = variableObject;
                // let PARAMETER = parameterObject;
                // let Moment = moment;
                // value = evalWrapper({
                //     moment : Moment,
                //     Moment,
                //     VARIABLE,
                //     PARAMETER,
                //     inputText
                // });
                // parameterObject['companyId'] = companyId;
                // parameterObject['functionId'] = functionId;
                // parameterObject['inWorkerThread'] = inWorkerThread;
                // companyId=null; 
                // functionId=null; 
                // inWorkerThread=null;
                // VARIABLE=null; 
                // PARAMETER=null; 
                // Moment=null;

                value = evalWrapper({
                    moment,
                    Moment : moment,
                    VARIABLE : variableObject,
                    PARAMETER : parameterObject,
                    inputText
                });
            }else{
                value = inputText;
            }
        }

        let mapValue;
        if(!isVariable){
            mapValue = value;
        }else{
            let rootMapValue, mappedVariableText='';
            if(variableType.toLowerCase() === 'variable'){
                mappedVariableText = (
                    variableIdMap['variable'] && variableIdMap['variable'][varId]
                ) || value;
                rootMapValue = updatedVariableObject[mappedVariableText];
            }else if(variableType.toLowerCase() === 'parameter'){
                mappedVariableText = (
                    variableIdMap['parameter'] && variableIdMap['parameter'][varId]
                ) || value;
                rootMapValue = parameterObject[mappedVariableText];
            }

            if(mappingFunctions.length > 0){
                let parsedResponse = parseMappingFunctions(
                    rootMapValue,
                    mappingFunctions,
                    updatedVariableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap
                )
                mapValue = parsedResponse.value;
                updatedVariableObject = parsedResponse.variableObject;
                parsedResponse = null;
            }else{
                mapValue = rootMapValue;
            }
        }
        value = null;
        let returnValue;
        if(expressionMethods.length){
            let parsedExpResponse = parseExpressionMethods(
                mapValue, expressionMethods,
                variableObject, parameterObject,
                functionMap, variableIdMap
            );
            returnValue = parsedExpResponse.value;
            updatedVariableObject = parsedExpResponse.variableObject;
        }else{
            returnValue = mapValue;
        }
        return {
            value : returnValue,
            variableObject : updatedVariableObject
        };

    }catch(e){
        console.log('/utils/syncFunctionParser/simpleScript/expressionParser/resolveExpression catch block error', e);
        return {
            value : undefined,
            variableObject 
        };
    }
}

const isJson = (obj) => {
    try{
        return JSON.stringify(obj)
    }catch(e){
        return false;
    }
}