import resolveExpression from '../simpleScript/expressionParser/resolveExpression';

export default function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
){
    try{
        const {
            functionType='',
            executionObject={}
        } = functionObj;
        const {
            path={}, naviagationType='', target=''
        } = executionObject;

        let variableCopy = variableObject;
        let resolvedData = resolveExpression(
            path,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedValue = resolvedData.value;
        variableCopy = resolvedData.variableObject;
        if(
            !resolvedValue ||
            (
                resolvedValue && 
                typeof resolvedValue !== 'string'
            )
        ){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Invalid path. Path must be a valid string.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(naviagationType === 'page'){
            const {inhouz_reserved_parameters_xyzh={}} = parameterObject;
            const {historyPushFunction} = inhouz_reserved_parameters_xyzh;
            if(historyPushFunction){
                historyPushFunction(resolvedValue);
            }
        }else{
            if(typeof window !== 'undefined' && (window && window.location)){
                let win = window.open(resolvedValue, target);
                if(win && (target === '_blank')){
                    win.focus();
                }
            }
        }

        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy,
            hasError : false,
            errorPayload : undefined
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/general/functionTypeParser/base64Parser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}