import resolveExpression from '../../expressionParser/resolveExpression';
import moment from 'moment';
import dotNotationMapper from '../../../../data/dotNotationMapper';
import dotNotationObjectUpdate from '../../../../data/dotNotationObjectUpdate';

export default async function (
    currentValue, method={},
    variableObject={}, parameterObject={},
    functionMap={}, variableIdMap={}
){
    try{
        const {
            methodData={}, methodName=''
        } = method;

        let updatedVariableObject = variableObject;
        let returnValue;
        if(methodName === 'slice'){
            let startIndexResponse = await resolveExpression(
                methodData['startIndex'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            )
            updatedVariableObject = startIndexResponse.variableObject;
            let endIndexResponse = await resolveExpression(
                methodData['endIndex'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = endIndexResponse.variableObject;
            returnValue = await currentValue.slice(
                startIndexResponse.value,
                endIndexResponse.value
            );
        }else if(methodName === 'substring'){
            let startIndexResponse = await resolveExpression(
                methodData['startIndex'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            )
            updatedVariableObject = startIndexResponse.variableObject;
            let endIndexResponse = await resolveExpression(
                methodData['endIndex'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = endIndexResponse.variableObject;
            returnValue = await currentValue.substring(
                startIndexResponse.value,
                endIndexResponse.value
            );
        }else if(methodName === 'replace'){
            let stringToReplace = await resolveExpression(
                methodData['stringToReplace'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            )
            updatedVariableObject = stringToReplace.variableObject;
            let replacementString = await resolveExpression(
                methodData['replacementString'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = replacementString.variableObject;
            returnValue = await currentValue.replace(
                stringToReplace.value,
                replacementString.value
            );
        }else if(methodName === 'stringToPEM'){
            if(typeof currentValue === 'string'){
                let firstLineText = await resolveExpression(
                    methodData['firstLineText'], updatedVariableObject,
                    parameterObject, functionMap, variableIdMap
                )
                updatedVariableObject = firstLineText.variableObject;
                let lastLineText = await resolveExpression(
                    methodData['lastLineText'], updatedVariableObject,
                    parameterObject, functionMap, variableIdMap
                );
                updatedVariableObject = lastLineText.variableObject;

                let replace1 = currentValue.replace(firstLineText.value, '');
                let replace2 = replace1.replace(lastLineText.value, '');
                let trimmedString = replace2.trim();
                trimmedString = trimmedString.replaceAll(' ', '');
                let PEM = ``
                PEM += `${firstLineText.value}\n`;
                while(trimmedString.length > 0) {
                    PEM += trimmedString.substring(0, 64) + '\n';
                    trimmedString = trimmedString.substring(64);
                }
                PEM += lastLineText.value;
                returnValue = PEM;
            }else{
                returnValue = currentValue;
            }
        }else if(methodName === 'setFullYear'){
            let year = await resolveExpression(
                methodData['year'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            )
            updatedVariableObject = year.variableObject;
            let month = await resolveExpression(
                methodData['month'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = month.variableObject;
            let day = await resolveExpression(
                methodData['day'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = day.variableObject;
            returnValue = await new Date(currentValue).setFullYear(
                year.value,
                month.value,
                day.value
            )
        }else if(methodName === 'setHours'){
            let hour = await resolveExpression(
                methodData['hour'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            )
            updatedVariableObject = hour.variableObject;
            let min = await resolveExpression(
                methodData['min'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = min.variableObject;
            let sec = await resolveExpression(
                methodData['sec'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = sec.variableObject;
            let milliseconds = await resolveExpression(
                methodData['milliseconds'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = milliseconds.variableObject;

            returnValue = await new Date(currentValue).setHours(
                hour.value,
                min.value,
                sec.value,
                milliseconds.value
            )
        }else if(methodName === 'setMinutes'){
            let min = await resolveExpression(
                methodData['min'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = min.variableObject;
            let sec = await resolveExpression(
                methodData['sec'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = sec.variableObject;
            let milliseconds = await resolveExpression(
                methodData['milliseconds'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = milliseconds.variableObject;

            returnValue = await new Date(currentValue).setMinutes(
                min.value,
                sec.value,
                milliseconds.value
            )
        }else if(methodName === 'setMonth'){
            let month = await resolveExpression(
                methodData['month'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = month.variableObject;
            let day = await resolveExpression(
                methodData['day'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = day.variableObject;
            returnValue = await new Date(currentValue).setMonth(
                month.value,
                day.value
            )
        }else if(methodName === 'setSeconds'){
            let seconds = await resolveExpression(
                methodData['seconds'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = seconds.variableObject;
            let milliseconds = await resolveExpression(
                methodData['milliseconds'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = milliseconds.variableObject;
            returnValue = await new Date(currentValue).setSeconds(
                seconds.value,
                milliseconds.value
            )
        }else if(methodName === 'setUTCFullYear'){
            let year = await resolveExpression(
                methodData['year'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            )
            updatedVariableObject = year.variableObject;
            let month = await resolveExpression(
                methodData['month'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = month.variableObject;
            let day = await resolveExpression(
                methodData['day'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = day.variableObject;
            returnValue = await new Date(currentValue).setUTCFullYear(
                year.value,
                month.value,
                day.value
            )
        }else if(methodName === 'setUTCHours'){
            let hour = await resolveExpression(
                methodData['hour'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            )
            updatedVariableObject = hour.variableObject;
            let min = await resolveExpression(
                methodData['min'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = min.variableObject;
            let sec = await resolveExpression(
                methodData['sec'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = sec.variableObject;
            let milliseconds = await resolveExpression(
                methodData['milliseconds'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = milliseconds.variableObject;
            returnValue = await new Date(currentValue).setUTCHours(
                hour.value,
                min.value,
                sec.value,
                milliseconds.value
            )
        }else if(methodName === 'setUTCMinutes'){
            let min = await resolveExpression(
                methodData['min'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = min.variableObject;
            let sec = await resolveExpression(
                methodData['sec'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = sec.variableObject;
            let milliseconds = await resolveExpression(
                methodData['milliseconds'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = milliseconds.variableObject;
            returnValue = await new Date(currentValue).setUTCMinutes(
                min.value,
                sec.value,
                milliseconds.value
            )
        }else if(methodName === 'setUTCMonth'){
            let month = await resolveExpression(
                methodData['month'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = month.variableObject;
            let day = await resolveExpression(
                methodData['day'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = day.variableObject;
            returnValue = await new Date(currentValue).setUTCMonth(
                month.value,
                day.value
            )
        }else if(methodName === 'setUTCSeconds'){
            let seconds = await resolveExpression(
                methodData['seconds'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = seconds.variableObject;
            let milliseconds = await resolveExpression(
                methodData['milliseconds'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = milliseconds.variableObject;
            returnValue = await new Date(currentValue).setUTCSeconds(
                seconds.value,
                milliseconds.value
            )
        }else if(['addDate', 'subtractDate'].includes(methodName)){
            let number = await resolveExpression(
                methodData['number'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject =number.variableObject;
            let duration = await resolveExpression(
                methodData['duration'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = duration.variableObject;
            if(methodName === 'addDate'){
                returnValue = moment(currentValue).add(number.value, duration.value);
            }else{
                returnValue = moment(currentValue).subtract(number.value, duration.value);
            }
        }else if(methodName === 'dotNotationMap'){
            let path = await resolveExpression(
                methodData['path'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = path.variableObject;
            let fallbackValue = await resolveExpression(
                methodData['fallbackValue'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = fallbackValue.variableObject;
            returnValue = dotNotationMapper(currentValue, path.value, true, fallbackValue.value);
        }else if(methodName === 'dotNotationSet'){
            let path = await resolveExpression(
                methodData['path'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = path.variableObject;
            let value = await resolveExpression(
                methodData['value'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = value.variableObject;
            returnValue = dotNotationObjectUpdate(currentValue, value.value, path.value);
        }

        return {
            value : returnValue,
            variableObject : updatedVariableObject
        }
    }catch(e){
        console.log('/utils/functionParser/simpleScript/expressionMethods/methodParser/variableCustomParamParser catch block error', e);
        return {
            value : undefined,
            variableObject 
        };
    }
}