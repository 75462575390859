export default function(file){
    try{
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        })
    }catch(e){
        console.log('/utils/base64/fileToBase64 catch error', e);
        return ''
    }
}