import resolveExpression from '../simpleScript/expressionParser/resolveExpression';
import basicEncryptDecrypt from '../../cryptography/basicEncryptDecrypt';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
){
    try{
        const {
            functionType='',
            executionObject={}
        } = functionObj;
        const {
            data={}, variableName='', 
            variableUnavailable=false, password={}
        } = executionObject;
        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }
        let variableCopy = variableObject;
        let resolvedData = await resolveExpression(
            data,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedValue = resolvedData.value;
        variableCopy = resolvedData.variableObject;
        let resolvedPassword = await resolveExpression(
            password,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedPasswordText = resolvedPassword.value;
        variableCopy = resolvedPassword.variableObject;
        let cryptoResult = basicEncryptDecrypt(
            resolvedValue,
            resolvedPasswordText,
            functionType === 'encrypt' ? true : false
        );
        variableCopy[variableName] = cryptoResult;
        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy,
            hasError : false,
            errorPayload : undefined
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/parseCrypto catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}