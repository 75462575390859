import simpleScriptParser from '../simpleScript/simpleScriptParser';
import recursiveFunctionStepParser from '../recursiveFunctionStepParser';
import functionResponseObjectExtractor from '../general/functionResponseObjectExtractor';

export default function(
    ifElseArray=[],
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
    setStateFunction
){
    try{
        let updatedVariables = variableObject;
        let returnObject = {
            updatedVariableObject : updatedVariables,
            hasError : false,
            hasReturnStatement : false,
            returnStatement : undefined
        }
        for (let i = 0; i < ifElseArray.length; i++){
            let ifElseObject = ifElseArray[i];
            const {
                condition=[],
                functionObject={}
            } = ifElseObject;
            let conditionPass = simpleScriptParser(
                condition,
                updatedVariables,
                parameterObject,
                functionMap,
                variableIdMap
            );
            if(conditionPass.value){
                const {
                    functionSteps=[]
                } = functionObject;
                let normalizedFunctionSteps = [];
                for (let k = 0; k < functionSteps.length; k++){
                    let workflow = functionSteps[k];
                    let steps = workflow['functionSteps'] || [];
                    normalizedFunctionSteps.push(...steps);
                }
                if(!normalizedFunctionSteps.length){
                    break;
                }
                let functionResponse = recursiveFunctionStepParser(
                    normalizedFunctionSteps, 
                    updatedVariables, 
                    parameterObject, 
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                returnObject = functionResponseObjectExtractor(functionResponse);
                break;
            }
        }

        return returnObject;
    }catch(e){
        console.log('/utils/syncFunctionParser/general/functionTypeParser/ifElseParser catch block error', e)
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}