import resolveExpression from "./resolveExpression";

export default async function(params={}){
    try{
        const {
            executionObject={}, variableObject={},
            parameterObject={}, functionMap={}, variableIdMap={},
            fieldList=[], useAllFields=false, skipList=[]
        } = params;
        let variableCopy = variableObject;
        let accumulator = {};

        if(useAllFields){
            let keys = Object.keys(executionObject);
            for (let i = 0; i < keys.length; i++){
                let field = keys[i];
                if(skipList.includes(field)){
                    continue;
                }
                let execObj = executionObject[field];
                if(execObj){
                    let resolveResponse = await resolveExpression(
                        execObj,
                        variableCopy,
                        parameterObject,
                        functionMap,
                        variableIdMap
                    );
                    let value = resolveResponse.value;
                    variableCopy = resolveResponse.variableObject;
                    accumulator[field] = value;
                }
            }
        }else{
            for (let i = 0; i < fieldList.length; i++){
                let field = fieldList[i];
                let execObj = executionObject[field];
                if(execObj){
                    let resolveResponse = await resolveExpression(
                        execObj,
                        variableCopy,
                        parameterObject,
                        functionMap,
                        variableIdMap
                    );
                    let value = resolveResponse.value;
                    variableCopy = resolveResponse.variableObject;
                    accumulator[field] = value;
                }
            }
        }

        return {
            accumulator,
            variableObject : variableCopy
        };
    }catch(e){
        console.log('/functionParser/simpleScript/expressionParser/bulkExpressionParser catch block error', e);
        return {
            error : {
                message : 'An error occurred while executing a bulk expression.'
            }
        }
    }
}