import { v4 as uuidv4 } from 'uuid';
import shortId from 'shortid';

export default async function(
    functionObj={},
    variableObject={}
){
    try{
        const {
            executionObject={}, functionType=''
        } = functionObj;
        const {
            idText={}, variableName='', variableUnavailable=false
        } = executionObject;
        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }
        let variableCopy = variableObject;
        let resolvedValue = functionType === 'shortid' ? shortId.generate() : uuidv4();
        variableCopy[variableName] = resolvedValue;
        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy,
            hasError : false,
            errorPayload : undefined
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/idParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}