import React, {Component} from 'react';
import {
    setSubscriptionAuthReducer, subscriptionChangePassword
} from '../../../actions';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { isEqual } from 'lodash';
import validator from 'validator';
import moment from 'moment';
import encryptDecrypt from '../../../utils/cryptography/encryptDecrypt';
import config from '../../../config/config';

class SubscriptionChangePassword extends Component{
    state = {
        passwordChangeSuccess : false
    }

    componentDidUpdate(prevProps){
        const {
            changePasswordLoading, changePasswordError
        } = this.props;

        if(
            !changePasswordLoading && 
            !changePasswordError &&
            !isEqual(changePasswordLoading, prevProps.changePasswordLoading)
        ){
            this.postPasswordChange();
        }
    }

    handleChangePassword = () => {
        const {
            email='', subscriptionChangePassword,
            assetId='', destination='', assetOwnerCompanyId='',
            companyId='', resetPassword='', newPassword=''
        } = this.props;
        if(subscriptionChangePassword){
            let encryptedData = encryptDecrypt(
                JSON.stringify({
                    data : {
                        subscriberEmail : email,
                        assetId,
                        destination,
                        assetOwnerCompanyId,
                        companyId,
                        changeRequestPassword : resetPassword,
                        newPassword
                    },
                    expirationTimestamp : moment().add(30, 'seconds').unix() * 1000
                }),
                true
            );
            subscriptionChangePassword({encryptedData});
        }
    }

    postPasswordChange = () => {
        const {
            setSubscriptionAuthReducer
        } = this.props;
        if(setSubscriptionAuthReducer){
            this.setState({
                passwordChangeSuccess : true
            }, () => {
                setTimeout(() => {
                    setSubscriptionAuthReducer({
                        passwordChangeInProgress : false,
                        resetPasswordEmail : '',
                        newPassword : ''
                    })
                }, 2000)
            });
        }
    }

    handleInput = (field, value) => {
        const {
            setSubscriptionAuthReducer
        } = this.props;

        if(setSubscriptionAuthReducer){
            setSubscriptionAuthReducer({
                [field] : value
            });
        }
    }

    render(){
        const {passwordChangeSuccess=false} = this.state;
        const {
            email='', resetPassword='', newPassword='',
            changePasswordLoading=false, changePasswordError=false,
            changePasswordErrorMessage=''
        } = this.props;
        const loading = changePasswordLoading;
        return (
            <div
                style={{
                    width : '100%'
                }}
            >
                {
                    changePasswordError && 
                    <div
                        className='alert alert-danger mb-3'
                    >
                        {
                            changePasswordErrorMessage ?
                            changePasswordErrorMessage
                            :
                            'Password change failed.'
                        }
                    </div>
                }
                {
                    passwordChangeSuccess && 
                    <div
                        className='alert alert-success my-1'
                    >
                        Success. Redirecting to sign in.
                    </div>
                }
                <div
                    className='text-center mb-3 text-dark'
                    style={{
                        fontSize : '18px',
                        fontWeight : 'bold'
                    }}
                >
                    Reset Password
                </div>
                <input 
                    className='form-control mb-3'
                    type='text'
                    placeholder='Email (Required)'
                    value={email}
                    onChange={(e) => this.handleInput('email', e.target.value)}
                />
                <input 
                    className='form-control mb-3'
                    type='password'
                    placeholder='Temporary password (Required: Check your email)'
                    value={resetPassword}
                    onChange={(e) => this.handleInput('resetPassword', e.target.value)}
                />
                <input 
                    className='form-control mb-3'
                    type='password'
                    placeholder='New password (Required)'
                    value={newPassword}
                    onChange={(e) => this.handleInput('newPassword', e.target.value)}
                />
                <div
                    className='d-flex align-items-center justify-content-center'
                >   
                    <button
                        className='btn btn-block btn-dark'
                        disabled={loading || !validator.isEmail(email) || !resetPassword || !newPassword}
                        onClick={() => this.handleChangePassword()}
                    >
                        {
                            changePasswordLoading ?
                            'Reset in progress...'
                            :
                            'Reset Password'
                        }
                    </button>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
        user : state.SubscriptionAuth.subscriberData,
        subcriberDataLoading: state.SubscriptionAuth.subcriberDataLoading,
        subscriberDataError : state.SubscriptionAuth.subscriberDataError,
        subscriberDataErrorMessage : state.SubscriptionAuth.subscriberDataErrorMessage,
        subscriberLoginLoading: state.SubscriptionAuth.subscriberLoginLoading,
        subscriberLoginError: state.SubscriptionAuth.subscriberLoginError,
        subscriberLoginErrorMessage: state.SubscriptionAuth.subscriberLoginErrorMessage,
        email : state.SubscriptionAuth.email,
        password : state.SubscriptionAuth.password,
        changePasswordLoading : state.SubscriptionAuth.changePasswordLoading,
        changePasswordError : state.SubscriptionAuth.changePasswordError,
        changePasswordErrorMessage : state.SubscriptionAuth.changePasswordErrorMessage,
        resetPassword : state.SubscriptionAuth.resetPassword,
        newPassword : state.SubscriptionAuth.newPassword
    }
}

export default connect(mapStateToProps, {
    setSubscriptionAuthReducer, subscriptionChangePassword
})(withRouter(SubscriptionChangePassword));