import resolveExpression from '../../../expressionParser/resolveExpression';
import update from 'immutability-helper';

export default function(
    arrayValue, methodData={}, variableObject={},
    parameterObject={}, functionMap={}, variableIdMap={}
){
    try{
        const {
            index={}, deleteCount={},
            itemsToAdd={}
        } = methodData;
        let upVariableObject = JSON.parse(JSON.stringify(variableObject));
        let parsedIndex = resolveExpression(
            index, upVariableObject,
            parameterObject, functionMap, variableIdMap
        );
        upVariableObject = JSON.parse(JSON.stringify(parsedIndex.variableObject));
        let parsedDeleteCount = resolveExpression(
            deleteCount, upVariableObject,
            parameterObject, functionMap, variableIdMap
        );
        upVariableObject = JSON.parse(JSON.stringify(parsedDeleteCount.variableObject));
        let parsedItemsToAdd = resolveExpression(
            itemsToAdd, upVariableObject,
            parameterObject, functionMap, variableIdMap
        );
        upVariableObject = JSON.parse(JSON.stringify(parsedItemsToAdd.variableObject));

        let arrayList;
        if(
            exists(parsedItemsToAdd.value)
        ){
            arrayList = update(arrayValue, {$splice : [[
                parsedIndex.value,
                parsedDeleteCount.value,
                parsedItemsToAdd.value
            ]]});
        }else{
            arrayList = update(arrayValue, {$splice : [[
                parsedIndex.value,
                parsedDeleteCount.value
            ]]});
        }
        let finalArray = [];
        if(
            exists(parsedItemsToAdd.value) && 
            Array.isArray(parsedItemsToAdd.value)
        ){
            for (let i = 0; i < arrayList.length; i++){
                let item = arrayList[i];
                if(i === parsedIndex.value){
                    for (let z = 0; z < item.length; z++){
                        let subItem = item[z];
                        finalArray.push(subItem);
                    }
                }else{
                    finalArray.push(item);
                }
            }
        }else{
            finalArray = arrayList.slice();
        }
        return {
            value : finalArray,
            variableObject : upVariableObject
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/simpleScript/expressionMethods/methodParsers/functionBased/spliceMethodParser', e);
        return {
            value : undefined,
            variableObject,
            hasError : true,
            errorPayload : e
        };
    }
}

function exists(data){
    if(
        Array.isArray(data)
    ){
        return data.length;
    }else if(
        typeof data === 'object'
    ){
        return Object.keys(data).length;
    }else if(typeof data === 'number'){
        return true;
    }else{
        if(data){
            return true;
        }else{
            return false;
        }
    }
}