import { isEqual } from 'lodash';
import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import elementAttributeFunction from './utils/functions/attributes/elementAttributeFunction';

class MetaTagsComponent extends Component{
    constructor(props){
        super(props);
        let initialState = this.extractMetaData(true);
        this.state = {
            seoSettings : {},
            ...initialState
        }
    }

    componentDidMount(){
        this.extractMetaData();
    }

    componentDidUpdate(prevProps){
        const {page, App_Data} = this.props;
        if(
            !isEqual(page, prevProps.page) ||
            !isEqual(App_Data, prevProps.App_Data)
        ){
            this.extractMetaData();
        }
    }

    extractMetaData = (returnState=false) => {
        const {
            page={}, appMap={}, appId='', setAppDataFunction,
            historyPushFunction, appEnvironment, setElementAppData
        } = this.props;
        const {
            dynamicSEO=false,
            pageData={}
        } = page;
        const {
            staticSEO={}, staticOpenGraph={},
            dynamicSEOTags={}, dynamicOpenGraph={}
        } = pageData;

        let seoObj = {};
        if(dynamicSEO){
            const appObj = appMap[appId] || {};
            const {
                App_Data={},
                appInFocus={}, mappedRouteParams={}, 
                mappedQueryStrings={},
                appFunctionMap
            } = appObj;
            const {appSettings={}} = appInFocus;
            const {user={}, userAgent={}} = appSettings;

            let params = {
                element : {
                    elementSettings : {
                        attributes : {
                            ...dynamicSEOTags,
                            ...dynamicOpenGraph
                        }
                    }
                },
                functionMap : appFunctionMap,
                App_Data,
                user,
                userAgent,
                setStateFunction : setAppDataFunction,
                companyId : appInFocus['companyId'],
                otherParameters : {
                    routeParameters : mappedRouteParams,
                    queryStringParameters : mappedQueryStrings,
                    inhouz_reserved_parameters_xyzh : {
                        historyPushFunction,
                        appEnvironment,
                        setElementAppData
                    }
                }
            }
            seoObj = elementAttributeFunction(params);
        }else{
            seoObj = {
                ...staticSEO,
                ...staticOpenGraph
            }
        }

        if(returnState){
            return {
                seoSettings : seoObj
            }
        }else{
            this.setState({
                seoSettings : seoObj
            })
        }
    }

    render(){
        const {
            seoSettings={}
        } = this.state;
        return (
            <div>
                <MetaTags>
                    {
                        seoSettings['metaTitle'] && 
                        <meta name="title" content={seoSettings['metaTitle']} />
                    }
                    {
                        seoSettings['metaTitle'] && 
                        <title>{seoSettings['metaTitle']}</title>
                    }
                    {
                        seoSettings['metaDescription'] && 
                        <meta name="description" content={seoSettings['metaDescription']} />
                    }
                    {
                        seoSettings['metaOgTitle'] && 
                        <meta property="og:title" content={seoSettings['metaOgTitle']} />
                    }
                    {
                        seoSettings['metaOgDescritpion'] && 
                        <meta property="og:description" content={seoSettings['metaOgDescritpion']} />
                    }
                    {
                        seoSettings['metaOgImageUrl'] && 
                        <meta property="og:image" content={seoSettings['metaOgImageUrl']} />
                    }
                    {
                        seoSettings['metaOgUrl'] && 
                        <meta property="og:url" content={seoSettings['metaOgUrl']} />
                    }
                    {
                        seoSettings['metaOgSiteName'] &&
                        <meta property="og:site_name" content={seoSettings['metaOgSiteName']} />
                    }
                </MetaTags>
            </div>
        )
    }
}

export default MetaTagsComponent;