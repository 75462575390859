export default async function(
    functionObj={},
    variableObject={},
){
    try{
        const {
            functionType='',
            executionObject={}
        } = functionObj;
        const {
            variableName='', variableUnavailable=false
        } = executionObject;
        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }
        let data = '';
        if(functionType === 'windowPathname'){
            data = window.location.pathname;
        }else if(functionType === 'windowHref'){
            data = window.location.href;
        }else if(functionType === 'windowOrigin'){
            data = window.location.origin;
        }else if(functionType === 'windowHostname'){
            data = window.location.hostname;
        }
        variableObject[variableName] = data;
        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableObject,
            hasError : false,
            errorPayload : undefined
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/idParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}