import encryptDecrypt from "../cryptography/encryptDecrypt";

export default function (){
    try{
        let map = {};
        let cookieName = process.env.RAZZLE_CSRF_KEY;
        let cookieString = document && document.cookie;
        let splitCookies = cookieString.split(';');
        for (let i = 0; i < splitCookies.length; i++){
            let cookieSetString = splitCookies[i];
            let subSplit = cookieSetString.split('=');
            if(
                subSplit.length < 2 ||
                subSplit.length > 2
            ){
                continue;
            }else{
                map[subSplit[0].trim()] = subSplit[1];
            }
        }
        let plusRegex = new RegExp(process.env.RAZZLE_PLUS_CRYPTO_STRING, 'g');
        let slashRegex = new RegExp(process.env.RAZZLE_SLASH_CRYPTO_STRING, 'g');
        let equalsRegex = new RegExp(process.env.RAZZLE_EQUALS_CRYPTO_STRING, 'g');
        if(map[cookieName]){
            let decrypted = encryptDecrypt(map[cookieName].replace(plusRegex, '+' ).replace(slashRegex, '/').replace(equalsRegex, '='), false);
            return decrypted;
        }else{
            return '';
        }
    }catch(e){
        console.log(e)
        return ''
    }
}