import { isEmpty, isEqual } from "lodash";
import dotNotationObjectUpdate from "../../utils/data/dotNotationObjectUpdate"
// import fastDeepEqual from 'fast-deep-equal';
// import ButtonViewer from '../../components/customComponents/appViewer/elementUi/elements/buttonViewer';

const initialState = {
    compressedBuild : '',
    appMap : {},
    user : {},
    userAgent : {},
    appDeviceMode : 'desktop',
    sliderPositionFieldMap : {},
    tabPositionFieldMap : {},
    tableColumnVisibilityMap : {},
    tableWidthMapCache : {},
    paymentElementPublicKeyCache : {},
    paymentElementPaymentIntentCache : {},
    listKeyTrackerMapCache : {},
    batchComputeCache : {},
    quickUpdateDataCache : {}
}

export default function(state=initialState, action){
    switch(action.type){
        case 'SET_APP_VIEWER_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'SET_APP_VIEWER_APP_OBJECT':
            return {
                ...state,
                appMap : {
                    ...state.appMap,
                    [action.payload.appId] : {
                        ...state.appMap[action.payload.appId],
                        ...action.payload.data
                    }
                }
            }
        case 'INITIALIZE_APP_VIEWER_APP':
            return {
                ...state,
                appMap : {
                    ...state.appMap,
                    [action.payload.appId] : action.payload.appData
                }
            }
        case 'UPDATE_GENERIC_ELEMENT_CACHE_IN_REDUCER':
            // let existingElementGenericCache = {};
            // if(
            //     state[action.payload.field] && 
            //     state[action.payload.field][action.payload.elementId]
            // ){
            //     existingElementGenericCache = state[action.payload.field][action.payload.elementId];
            // }
            return {
                ...state,
                [action.payload.field] : {
                    ...state[action.payload.field],
                    [action.payload.elementId] : {
                        // ...existingElementGenericCache,
                        ...state[action.payload.field][action.payload.elementId],
                        ...action.payload.data
                    }
                }
            }
        case 'SET_VIEWER_APP_DATA':
            return {
                ...state,
                appMap : {
                    ...state.appMap,
                    [action.payload.appId] : {
                        ...state.appMap[action.payload.appId],
                        App_Data : {
                            ...state.appMap[action.payload.appId]['App_Data'],
                            ...action.payload.data,
                            appSettings : state.appMap[action.payload.appId]['App_Data']['appSettings']
                        }
                    }
                }
            }
        case 'SET_APP_VIEWER_DATA_IN_REDUCER':
            let path = action.payload.path || '';
            let updateData = action.payload.data || '';
            let finalUpdate = {};
            if(path){
                finalUpdate = dotNotationObjectUpdate(
                    state.appMap[action.payload.appId]['App_Data'],
                    updateData,
                    path
                );
            }else{
                finalUpdate = {
                    ...state.appMap[action.payload.appId]['App_Data'],
                    ...updateData
                }
            }

            return {
                ...state,
                appMap : {
                    ...state.appMap,
                    [action.payload.appId] : {
                        ...state.appMap[action.payload.appId],
                        App_Data : {
                            ...finalUpdate,
                            appSettings : state.appMap[action.payload.appId]['App_Data']['appSettings']
                        }
                    }
                }
            }
        case 'SET_VIEWER_APP_SETTINGS':
            return {
                ...state,
                appMap : {
                    ...state.appMap,
                    [action.payload.appId] : {
                        ...state.appMap[action.payload.appId],
                        App_Data : {
                            ...state.appMap[action.payload.appId]['App_Data'],
                            appSettings : {
                                ...state.appMap[action.payload.appId]['App_Data']['appSettings'],
                                ...action.payload.data
                            }
                        }
                    }
                }
            }
        case 'SET_WEBSITE_RESOURCES':
            return {
                ...state,
                appMap : {
                    ...state.appMap,
                    [action.payload.appId] : {
                        ...state.appMap[action.payload.appId],
                        ...action.payload.resources,
                        appDataChangeTracker : Math.random(),
                        websiteResourceUpdateTracker : Math.random(),
                        websiteResourcesSet : true
                    }
                }
            }
        case 'SET_WEBSITE_PAGE_RESOURCES':
            if(state.websiteResourcesSet){
                return;
            }

            return {
                ...state,
                appMap : {
                    ...state.appMap,
                    [action.payload.appId] : {
                        ...state.appMap[action.payload.appId],
                        // ...action.payload.resources,
                        appElementsMap : {
                            ...state.appMap[action.payload.appId]['appElementsMap'],
                            ...action.payload.resources['appElementsMap']
                        },
                        appFunctionMap : {
                            ...state.appMap[action.payload.appId]['appFunctionMap'],
                            ...action.payload.resources['appFunctionMap']
                        },
                        parsedPageMap : {
                            ...state.appMap[action.payload.appId]['parsedPageMap'],
                            pageMap : {
                                ...state.appMap[action.payload.appId]['parsedPageMap']['pageMap'],
                                ...action.payload.resources['pageMap']
                            }
                        },
                        appDataChangeTracker : Math.random(),
                        websiteResourceUpdateTracker : Math.random(),
                        pageResourceUpdateTracker : Math.random()
                    }
                }
            }
        case 'SET_VIEWER_ELEMENT_APP_DATA':
            return {
                ...state,
                appMap : {
                    ...state.appMap,
                    [action.payload.appId] : {
                        ...state.appMap[action.payload.appId],
                        elementAppData : {
                            ...state.appMap[action.payload.appId].elementAppData,
                            [action.payload.elementId] : state.appMap[action.payload.appId].elementAppData[action.payload.elementId] ? 
                            {
                                ...state.appMap[action.payload.appId].elementAppData[action.payload.elementId],
                                ...action.payload.data
                            }
                            :
                            action.payload.data
                        },
                        elementAppDataTracker : {
                            ...state.appMap[action.payload.appId].elementAppDataTracker,
                            [action.payload.elementId] : action.payload.refresh ? 
                            Math.random() : state.appMap[action.payload.appId].elementAppDataTracker[action.payload.elementId]
                        },
                        clearElementAppDataTracker : {
                            ...state.appMap[action.payload.appId].clearElementAppDataTracker,
                            [action.payload.elementId] : action.payload.clearCache
                        }
                    }
                }
            }
        case 'SET_VIEWER_WEB_COMPONENT':
            return {
                ...state,
                appMap : {
                    ...state.appMap,
                    [action.payload.appInFocusId] : {
                        ...state.appMap[action.payload.appInFocusId],
                        webComponentCache : {
                            ...state.appMap[action.payload.appInFocusId]['webComponentCache'],
                            [action.payload.appId] : action.payload.app
                        }
                    }
                }
            }
        // case 'SET_BATCH_COMPUTE_CACHE':
        //     return {
        //         ...state,
        //         batchComputeCache : {
        //             ...state.batchComputeCache,
        //             [action.payload.appId] : {
        //                 ...state.batchComputeCache[action.payload.appId],
        //                 [`${action.payload.elementId}_${action.payload.listItemPathId}`] : action.payload.data
        //             }
        //         }
        //     }
        case 'SET_BATCH_COMPUTE_CACHE':
            for (let k in action.payload.data){
                if(state['batchComputeCache'][k]){
                    if(
                        isEqual(
                            state['batchComputeCache'][k]['dynamicAttributes'],
                            action.payload.data[k]['dynamicAttributes']
                        )
                    ){
                        delete action.payload.data[k];
                    }
                }
            }

            if(!isEmpty(action.payload.data)){
                return {
                    ...state,
                    batchComputeCache : {
                        ...state.batchComputeCache,
                        ...action.payload.data
                    }
                }
            }else{
                return state;
            }
        case 'UNSET_BATCH_COMPUTE_CACHE':
            return {
                ...state,
                batchComputeCache : {
                    ...state.batchComputeCache,
                    [action.payload.key] : null
                }
            }
        case 'SET_QUICK_UPDATE_DATA_CACHE':
            return {
                ...state,
                quickUpdateDataCache : {
                    ...state.quickUpdateDataCache,
                    [action.payload.key] : action.payload.data
                }
            }
        default:
            return state;
    }
}