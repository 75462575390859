import operatorParser from './algorithmOperators/operatorParser';
import resolveExpression from './expressionParser/resolveExpression';
import parseExpressionMethods from './expressionMethods/parseExpressionMethods';

export default function (
    scriptArray=[], 
    variableObject={}, 
    parameterObject={}, 
    functionMap={},
    variableIdMap={}
){
    try{    
        let bracketCount = 0;
        let bracketMap = {};
        let accumulator;
        let subBracketOperator = {};
        let mainOperator = '';

        let updatedVariableObject = variableObject;
        for (let i = 0; i < scriptArray.length; i++){
            const scriptObject = scriptArray[i];
            const {
                unitType='', inputText='',
                expressionMethods=[]
            } = scriptObject;

            if(unitType === 'bracket'){
                if(inputText === 'openBracket'){
                    bracketCount++;
                    subBracketOperator[bracketCount] = '';
                }else{
                    let mappedData = bracketMap[bracketCount];
                    let parsedData;
                    if(expressionMethods.length > 0){
                        let parsedResponse = parseExpressionMethods(
                            mappedData, expressionMethods,
                            updatedVariableObject, parameterObject,
                            functionMap, variableIdMap
                        )
                        // console.log('parsedMethodResponse', parsedResponse)
                        updatedVariableObject = parsedResponse.variableObject;
                        parsedData = parsedResponse.value;
                    }else{
                        parsedData = mappedData;
                    }
                    
                    if(bracketCount > 1){
                        bracketMap[bracketCount] = operatorParser(
                            bracketMap[bracketCount - 1], 
                            parsedData, 
                            subBracketOperator[bracketCount - 1]
                        );
                    }else{
                        accumulator = operatorParser(accumulator, parsedData, mainOperator);
                    }
                    delete bracketMap[bracketCount];
                    delete subBracketOperator[bracketCount];
                    bracketCount--;
                }
            }else if(unitType === 'operator'){
                if(bracketCount > 0){
                    subBracketOperator[bracketCount] = inputText;
                }else{
                    mainOperator = inputText;
                }
            }else if(unitType === 'expression'){
                let resolvedExpression = resolveExpression(
                    scriptObject,
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap
                );
                // console.log('expressionResponse', resolvedExpression)
                
                updatedVariableObject = resolvedExpression.variableObject;
                if(bracketCount > 0){
                    bracketMap[bracketCount] = operatorParser(
                        bracketMap[bracketCount], 
                        resolvedExpression.value, 
                        subBracketOperator[bracketCount]
                    );
                }else{
                    accumulator = operatorParser(
                        accumulator, 
                        resolvedExpression.value, 
                        mainOperator
                    );
                }
            }
        }
        return {
            value : accumulator,
            variableObject : updatedVariableObject
        };
    }catch(e){
        console.log('/utils/syncFunctionParser/simpleScript/simpleScriptParser catch block error', e)
        return {
            value : undefined,
            variableObject 
        };
    }
}

