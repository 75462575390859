import resolveExpression from '../simpleScript/expressionParser/resolveExpression';
import encryptDecrypt from '../../cryptography/encryptDecrypt';
import config from '../../../config/config';
import moment from 'moment';
import shortid from 'shortid';

export default async function (
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
){
    try{
        const {
            functionType='',
            executionObject={}
        } = functionObj;
        const {
            variableName='', 
            variableUnavailable=false, 
            // companyId='',
            assetType='', subscriberType='', activeOnly={},
            assetId={}, tenantId={}
        } = executionObject;
        const {
            companyId='', inhouz_reserved_parameters_xyzh={}, user={}
        } = parameterObject;
        const {appEnvironment=''} = inhouz_reserved_parameters_xyzh;
        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(
            !appEnvironment ||
            (
                appEnvironment && 
                typeof appEnvironment !== 'string'
            )
        ){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Runtime environment is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(
            !companyId || 
            (
                companyId && 
                typeof companyId !== 'string'
            )
        ){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Account identifier is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let variableCopy = variableObject;
        let resolvedAssetId = await resolveExpression(
            assetId,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedAssetIdValue = resolvedAssetId.value;
        variableCopy = resolvedAssetId.variableObject;

        if(
            !resolvedAssetIdValue ||
            (
                resolvedAssetIdValue && 
                typeof resolvedAssetIdValue !== 'string'
            )
        ){
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Asset ID is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let resolvedActiveOnly = await resolveExpression(
            activeOnly,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedActiveOnlyValue = resolvedActiveOnly.value;
        variableCopy = resolvedActiveOnly.variableObject;

        let resolvedTenantId = await resolveExpression(
            tenantId,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedTenantIdValue = resolvedTenantId.value;
        variableCopy = resolvedTenantId.variableObject;

        let payload = {
            functionType,
            companyId,
            queryKey : shortid.generate(),
            assetId : resolvedAssetIdValue,
            tenantId : resolvedTenantIdValue,
            activeOnly : resolvedActiveOnlyValue,
            environment : appEnvironment === 'appbuilder' ? 'development' : appEnvironment,
            subscriberType,
            assetType
        }

        let encryptedPayload = encryptDecrypt(
            JSON.stringify({
                payload,
                expirationTimestamp : moment().add(60, 'seconds').unix() * 1000
            }),
            true
        );

        let response = await fetch(
            `${config.devServerProxy}/api/subscriberdatafunction/getsubscribercount`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache'
                },
                body : JSON.stringify({encryptedPayload})
            }
        )
        .then(resp => resp.json())
        .catch((error) => {
            return {
                error : {
                    message : error.message
                }
            }
        });
        variableCopy[variableName] = response;
        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/getSubscriberCountParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}