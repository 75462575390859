import {
    isEmpty
} from 'lodash';
import recursiveFunctionStepParser from './recursiveFunctionStepParser';
import resolveExpression from './simpleScript/expressionParser/resolveExpression';

export default async function(
    functionObject={}, 
    parameterObj={}, 
    setStateFunction,
    functionMap,
    mappedVariables={}
){
    try{
        let {
            variables=[], parameters=[], functionSteps=[]
        } = functionObject;
        let variableMap = mappedVariables, parameterObject = parameterObj;

        let variableIdMap={
            variable : {},
            parameter : {},
            state : {}
        };
        //initialize variables
        if(
            !mappedVariables ||
            isEmpty(mappedVariables)
        ){
            for (let i = 0; i < variables.length; i++){
                let variableObject = variables[i];
                let {
                    variableName='', variableDefaultValue,
                    variableId=''
                } = variableObject;
                variableMap[variableName] = variableDefaultValue;
                variableIdMap['variable'][variableId] = variableName;
            }
        }

        for (let i = 0; i < parameters.length; i++){
            let parameter = parameters[i];
            const {
                parameterName='', parameterDefaultValue,
                parameterId=''
            } = parameter;
            variableIdMap['parameter'][parameterId] = parameterName;
            if(!parameterObj[parameterName]){
                let resolvedExpression = await resolveExpression(parameterDefaultValue);
                parameterObject[parameterName] = resolvedExpression.value;
            }
        }

        //normalize workflows into functionSteps
        let normalizedFunctionSteps = [];
        for (let i = 0; i < functionSteps.length; i++){
            let workflow = functionSteps[i];
            let steps = workflow['functionSteps'] || [];
            normalizedFunctionSteps.push(...steps);
        }

        let functionResponse = await recursiveFunctionStepParser(
            normalizedFunctionSteps, 
            variableMap, 
            parameterObject, 
            functionMap,
            variableIdMap,
            setStateFunction
        );

        functionObject = null;
        parameterObj = null;
        
        functionMap = null;
        mappedVariables = null;
        variables = null;
        parameters = null;
        functionSteps = null;
        variableMap = null;
        parameterObject = null;
        variableIdMap = null;
        normalizedFunctionSteps = null;
        return functionResponse;
    }catch(e){
        console.log('/utils/functionParser/functionParser catch block error', e);
        return {
            error : {
                errorPayload : e
            }
        };
    }
}