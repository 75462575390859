export default function(props={}){
    try{
        const {
            pageRootElementMap={}, appElementsMap={},
            tableIdMap={}, sliderIdMap={}, tabIdMap={}
        } = props;

        let accumulator = {};
        for (let k in pageRootElementMap){
            let rootElementId = pageRootElementMap[k];
            let rootElement = appElementsMap[rootElementId] || {};
            let map = recursiveParentMap(rootElement['elementId'], appElementsMap, []);
            accumulator = {
                ...accumulator,
                ...map
            }
        }
        
        //reconcile tables
        for (let i in tableIdMap){
            let tableElement = tableIdMap[i];
            if(tableElement){
                let {
                    nestedElementIds=[], elementSettings={}
                } = tableElement;
                let {
                    columnCellElementIds=[], columnFilterElementIds=[],
                    columnHeaderElementIds=[]
                } = elementSettings;
                let executionMap = {
                    0 : columnHeaderElementIds,
                    1 : columnFilterElementIds,
                    2 : columnCellElementIds
                }
                for (let step in executionMap){
                    let elementList = executionMap[step];
                    let parentId;
                    if(step !== '2'){
                        parentId = nestedElementIds[parseInt(step)];
                    }else{
                        let tableDataWrapper = appElementsMap[nestedElementIds[parseInt(step)]]
                        if(tableDataWrapper){
                            let dataNestedIds = tableDataWrapper['nestedElementIds'];
                            if(dataNestedIds){
                                parentId = dataNestedIds[0];
                            }
                        }
                    }

                    if(parentId){
                        for (let z = 0; z < elementList.length; z++){
                            let listElementId = elementList[z];
                            let parentParents = accumulator[parentId] || [];
                            let miniAccumulator = recursiveParentMap(listElementId, appElementsMap, [...parentParents, parentId]);
                            accumulator = {
                                ...miniAccumulator,
                                ...accumulator
                            }
                        }
                    }
                }
            }
        }

        //reconcile sliders
        for (let slideId in sliderIdMap){
            let slider = sliderIdMap[slideId];
            if(slider){
                let {elementSettings={}} = slider;
                let {slideElementIds=[]} = elementSettings;
                let sliderParents = accumulator[slideId] || [];
                for (let i = 0; i < slideElementIds.length; i++){
                    let miniAccumulator = recursiveParentMap(slideElementIds[i], appElementsMap, [...sliderParents, slideId]);
                    accumulator = {
                        ...miniAccumulator,
                        ...accumulator
                    }
                }
            }
        }

        //reconcile tabs
        for (let tabWrapperId in tabIdMap){
            let tabWrapper = tabIdMap[tabWrapperId];
            if(tabWrapper){
                let {elementSettings={}} = tabWrapper;
                let {
                    tabContentElementId=[],
                    tabMenuElementId=[]
                } = elementSettings;
                let tabIdList = [...tabMenuElementId, ...tabContentElementId];
                let tabWrapperParents = accumulator[tabWrapperId] || [];
                for (let i = 0; i < tabIdList.length; i++){
                    let miniAccumulator = recursiveParentMap(tabIdList[i], appElementsMap, [...tabWrapperParents, tabWrapperId]);
                    accumulator = {
                        ...miniAccumulator,
                        ...accumulator
                    }
                }
            }
        }

        return accumulator;
    }catch(e){
        console.log('/appBuilder/utils/functions/element/getParentElementHeirarchy catch error', e);
        return;
    }
}

const recursiveParentMap = (id, appElementsMap={}, parents=[]) => {
    let map = {};
    if(Array.isArray(id)){
        for (let i = 0; i < id.length; i++){
            map = {
                ...map,
                ...recursiveParentMap(id[i], appElementsMap, parents)
            }
        }
    }else{
        let element = appElementsMap[id];
        if(element){
            let {nestedElementIds=[]} = element;
            map[id] = parents;
            map = {
                ...map,
                ...recursiveParentMap(nestedElementIds, appElementsMap, [...parents, id])
            }
        }
    }
    return map;
}