import resolveExpression from '../simpleScript/expressionParser/resolveExpression';
import * as bufferFrom from 'buffer-from';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            data={}, variableName='', variableUnavailable=false
        } = executionObject;
        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }
        let variableCopy = variableObject;
        let resolvedData = await resolveExpression(
            data,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedValue = resolvedData.value;
        variableCopy = resolvedData.variableObject;
        let parsedValue = bufferFrom(resolvedValue);
        variableCopy[variableName] = parsedValue;
        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy,
            hasError : false,
            errorPayload : undefined
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/base64Parser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}