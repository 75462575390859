// import safeEval from 'safe-eval';

export default function({
    PARAMETER={}, VARIABLE={}, inputText='',
    Moment, moment
}){
    let value;
    try{
        value = eval(inputText);
        PARAMETER = null;
        VARIABLE = null;
        inputText = null;
        Moment = null;
        moment = null;
        return value;
    }catch(e){
        console.log('functionParser eval', e.message, inputText);
        if(
            e.message.includes("Unexpected token ':'") ||
            e.message.includes("Unexpected token '...'")
        ){
            try{
                value = eval('(' + inputText + ')');
                PARAMETER = null;
                VARIABLE = null;
                inputText = null;
                Moment = null;
                moment = null;
                return value;
            }catch(err){
                console.log('FunctionParser eval (TWO)', err.message, inputText);
                PARAMETER = null;
                VARIABLE = null;
                inputText = null;
                Moment = null;
                moment = null;
                value = null;
                throw err;
            }
        }else{
            PARAMETER = null;
            VARIABLE = null;
            inputText = null;
            Moment = null;
            moment = null;
            value = null;
            throw e;
        }
    }
}