import encryptDecrypt from '../../cryptography/encryptDecrypt';
import config from '../../../config/config';
import moment from 'moment';
import bulkExpressionParser from '../simpleScript/expressionParser/bulkExpressionParser';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        const {
            functionType='',
            executionObject={}
        } = functionObj;
        const {
            variableName='', 
            variableUnavailable=false, 
        } = executionObject;
        const {
            companyId='', inhouz_reserved_parameters_xyzh={}, user={}
        } = parameterObject;
        const {appEnvironment=''} = inhouz_reserved_parameters_xyzh;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let parsedParameters = await bulkExpressionParser({
            executionObject,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap,
            fieldList : [
                'emailList',
                'assetId',
                'subscriptionServiceId',
                'X-API-KEY'
            ]
        });

        if(parsedParameters.error){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Failed to resolve function parameters.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let variableCopy = parsedParameters['variableObject'];
        let resolvedParams = parsedParameters['accumulator'];

        let payload = {
            assetId : resolvedParams['assetId'] || '',
            subscriptionServiceId : resolvedParams['subscriptionServiceId'] || '',
            assetOwnerCompanyId : companyId,
            queryKey : `${Math.random()}${Math.random()}${Math.random()}${Math.random()}`
        }

        if(functionType === 'addToSubscriptionUserBlockList'){
            payload['action'] = 'push';
            payload['restrictionType'] = 'userBlockList';
        }else if(functionType === 'removeFromSubscriptionUserBlockList'){
            payload['action'] = 'pull';
            payload['restrictionType'] = 'userBlockList';
        }else if(functionType === 'addToSubscriptionUserAllowList'){
            payload['action'] = 'push';
            payload['restrictionType'] = 'userAllowList';
        }else if(functionType === 'removeFromSubscriptionUserAllowList'){
            payload['action'] = 'pull';
            payload['restrictionType'] = 'userAllowList';
        }

        let encryptedPayload = encryptDecrypt(
            JSON.stringify({
                payload,
                expirationTimestamp : moment().add(60, 'seconds').unix() * 1000
            }),
            true
        );

        let encryptedAccessHeader = '', clientEncryptedApiHeader='';
        if(resolvedParams['X-API-KEY']){
            clientEncryptedApiHeader = encryptDecrypt(
                JSON.stringify({
                    payload : {
                        'X-API-KEY' : resolvedParams['X-API-KEY'],
                        companyId,
                        queryKey : `${Math.random()}${Math.random()}${Math.random()}${new Date().getTime()}`,
                        environment : appEnvironment
                    },
                    expirationTimestamp : moment().add(600, 'seconds').unix() * 1000
                }),
                true
            );
        }else{
            if(user && user.isAuthenticated){
                encryptedAccessHeader = encryptDecrypt(
                    JSON.stringify({
                        payload : {
                            assetOwnerCompanyId : companyId,
                            companyId : user['companyId'],
                            email : user['email'],
                            subscriptionId : user['subscriptionId'],
                            subscriptionServiceId : user['subscriptionServiceId'],
                            environment : appEnvironment,
                            _id : user['_id'],
                            userId : user['_id'],
                            isInternal : (user['subscriberType'] === 'internal') || user['isInternal'] ? true : false,
                            isSystemUser : user['isSystemUser'] ? true : false
                        },
                        expirationTimestamp : moment().add(600, 'seconds').unix() * 1000
                    }),
                    true
                );
            }
        }

        let response = await fetch(
            `${config.devServerProxy}/api/subscriptionservice/manageuserrestriction`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache',
                    'clientEncryptedAccessHeader' : encryptedAccessHeader,
                    'X-CE-API-KEY' : clientEncryptedApiHeader
                },
                credentials : 'omit',
                body : JSON.stringify({
                    encryptedPayload,
                    emailList : resolvedParams['emailList'] || []
                })
            }
        )
        .then(resp => resp.json())
        .catch((error) => {
            return {
                error : {
                    message : error.message
                }
            }
        });

        variableCopy[variableName] = response;
        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/manageSubscriptionUserRestrictionParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}