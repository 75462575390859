import localforage from "localforage";
import { delMany, keys, del } from 'idb-keyval';

export default async function(params={}){
    try{
        let isOldIE = false;
        if(
            navigator && 
            (
                navigator.userAgent.indexOf('MSIE')!==-1
                || navigator.appVersion.indexOf('Trident/') > -1
            )
        ){
            isOldIE = true;
        }

        const {
            keysToDelete=[], indexDbName='', storeName='',
            sessionKeys=[], appId='', bulkClear=false
        } = params;
        if(isOldIE){
            localforage.config({
                name : indexDbName,
                driver : localforage.INDEXEDDB,
                storeName
            });
            localforage.clear()
            .catch(e => {
                console.log('indexedDbClear localForage clear catch error', e);
                return e;
            });
            localforage.dropInstance({
                name : indexDbName,
                storeName : storeName
            })
            .catch(e => {
                console.log('indexedDbClear localForage dropInstance catch error', e);

            });
        }else{
            delMany(keysToDelete);
            if(bulkClear){
                let filteredSessionKeys = sessionKeys.filter(item => {
                    return item !== storeName;
                });
                localStorage.setItem(`${appId}_keys`, JSON.stringify(filteredSessionKeys));
                if(filteredSessionKeys.length === 0){
                    keys()
                    .then((keyList) => {
                        // let badKeys = [];
                        for (let i = 0; i < keyList.length; i++){
                            let keyName = keyList[i];
                            let appRef = keyName.split('_')[0];
                            if(
                                [
                                    appId, 'null'
                                ].includes(appRef)
                            ){
                                // badKeys.push(keyName);
                                del(keyName);
                            }
                        }
                        // return badKeys;
                    });
                    // delMany(invalidKeys);
                    // invalidKeys = null;
                }
                filteredSessionKeys = null;
            }
        }
        return {done : true};
    }catch(e){
        console.log('/indexedDbSetItem catch block error', e);
        return null;
    }
}