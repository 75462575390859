import getCsrf from '../../cookies/getCsrf';
import encryptDecrypt from '../../cryptography/encryptDecrypt';
import config from '../../../config/config';
import moment from 'moment';

export default async function (
    functionObj={},
    variableObject={},
    parameterObject={}
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            secretKeyId='',
            variableName='', variableUnavailable=false
        } = executionObject;
        const {
            companyId='', inhouz_reserved_parameters_xyzh={}
        } = parameterObject;
        const {appEnvironment=''} = inhouz_reserved_parameters_xyzh;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(
            !companyId ||
            typeof companyId !== 'string'
        ){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Owner ID is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(
            !secretKeyId ||
            typeof secretKeyId !== 'string'
        ){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Secret Key is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let encryptedCompanyId = encryptDecrypt(
            JSON.stringify({
                companyId,
                expirationTimestamp : moment().add(30, 'seconds').unix() * 1000
            }),
            true
        );

        //get secret
        let secretObj = await fetch(
            `${config.devServerProxy}/api/secret/getsecret`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache',
                    'x-csrfToken' : getCsrf()
                },
                credentials : 'include',
                body : JSON.stringify({
                    secretKeyId,
                    encryptedCompanyId,
                    environment : appEnvironment
                })
            }
        )
        .then(res => {
            if(res.status !== 200){
                return {
                    error : {
                        message : 'An error occured while getting secret securely.'
                    }
                }
            }
            let jsonResponse = res.json();
            if(jsonResponse.error){
                return {
                    error : {
                        message : jsonResponse.error.message
                    }
                }
            }
            return jsonResponse;
        })
        .catch((error) => {
            console.log('secretKeyParser catch block error', error);
            return {
                error : {
                    message : ''
                }
            };
        });

        if(secretObj.error){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : secretObj.error.message || 'An error occured while getting secret securely.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let {
            secretValue=''
        } = secretObj;

        variableObject[variableName] = encryptDecrypt(secretValue, false);
        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableObject,
            hasError : secretObj.hasError || secretObj.error ? true : false,
            errorPayload : secretObj.hasError || secretObj.error ? secretObj : undefined
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/general/functionTypeParser/networkRequestParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}