import resolveExpression from '../simpleScript/expressionParser/resolveExpression';

export default function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
){
    try{
        const {
            functionType='',
            executionObject={}
        } = functionObj;
        const {
            objectList={}, variableName='', variableUnavailable=false
        } = executionObject;
        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }
        let variableCopy = variableObject;
        let resolvedData = resolveExpression(
            objectList,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedValue = resolvedData.value;
        variableCopy = resolvedData.variableObject;

        if(!Array.isArray(resolvedValue)){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Type error: MergeObject function must resolve an array of objects',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let accumulator = {};
        for (let i = 0; i < resolvedValue.length; i++){
            let obj = resolvedValue[i];
            if(
                typeof obj === 'object' && 
                !Array.isArray(obj)
            ){
                accumulator = Object.assign(accumulator, obj);
            }
        }
        variableCopy[variableName] = accumulator;
        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy,
            hasError : false,
            errorPayload : undefined
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/general/functionTypeParser/objectAssignParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}