const initialState = {
    subscriberData : {},
    subcriberDataLoading : false,
    subscriberDataError : false,
    subscriberDataErrorMessage : '',
    subscriberLoginLoading : false,
    subscriberLoginError : false,
    subscriberLoginErrorMessage : false,
    validateTwoFactorLoading : false,
    validateTwoFactorError : false,
    validateTwoFactorErrorMessage : '',
    validateTwoFactorResponse : {},
    regenerateTwoFactorLoading : false,
    regenerateTwoFactorError : false,
    regenerateTwoFactorErrorMessage : '',
    forgotPasswordLoading : false,
    forgotPasswordError : false,
    forgotPasswordErrorMessage : '',
    changePasswordLoading : false,
    changePasswordError : false,
    changePasswordErrorMessage : '',
    logoutIsLoading : false,
    logoutError : false,
    logoutErrorMessage : '',
    resetPassword : '',
    newPassword : '',
    resetPasswordEmail : '',
    email : '',
    password : '',
    twoFactorRequired : false,
    passwordChangeInProgress : false,
    validatorId : '',
    twoFactorCode : '',
    loginMode : false,
    logoutMode : false,
    passwordValidationLoading : false,
    passwordValidationError : false,
    passwordValidationErrorMessage : '',
    appVisitLogLoading : false,
    appVisitLogError : false,
    appVisitLogErrorMessage : ''
}

export default function(state=initialState, action){
    switch(action.type){
        case 'SET_SUBSCRIPTION_AUTH_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_SUBSCRIPTION_AUTH_REDUCER':
            return initialState;
        default:
            return state;
    }
}