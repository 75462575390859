import resolveExpression from '../simpleScript/expressionParser/resolveExpression';
import { btoa } from 'js-base64';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            data={}, variableName='', variableUnavailable=false
        } = executionObject;
        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }
        let variableCopy = variableObject;
        let resolvedData = await resolveExpression(
            data,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedValue = resolvedData.value;
        variableCopy = resolvedData.variableObject;
        let bufferArray;
        if(typeof resolvedValue === 'object' && !Array.isArray(resolvedValue)){
            bufferArray = (resolvedValue && resolvedValue.data) || resolvedValue;
        }else{
            bufferArray = resolvedValue;
        }
        variableCopy[variableName] = _arrayBufferToBase64(bufferArray);
        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy,
            hasError : false,
            errorPayload : undefined
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/base64Parser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}

function _arrayBufferToBase64( buffer ) {
    let binary = '';
    let bytes = new Uint8Array( buffer );
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return btoa( binary );
}