import simpleScriptParser from '../../functionParser/simpleScript/simpleScriptParser';

async function scriptObjectParser(
    scriptObject, variableObject, 
    parameterObject, functionMap={}, variableIdMap
){
    try{
        const {
            inputText = {}
        } = scriptObject;
        let {
            blockType='', blockContent=[]
        } = inputText;
        let accumulator = blockType === 'object' ? {} : [];
        let updatedVariableObject = variableObject;
        for (let i = 0; i < blockContent.length; i++){
            let blockData = blockContent[i];
            let {
                blockType='', blockName={},
                blockValue=[]
            } = blockData;
            let parsedBlockName, parsedBlockValue;
            if(blockType === 'field'){
                parsedBlockName = await simpleScriptParser(
                    [blockName],
                    variableObject,
                    parameterObject,
                    functionMap,
                    variableIdMap
                )
                updatedVariableObject = parsedBlockName.variableObject;
            }
            parsedBlockValue = await simpleScriptParser(
                blockValue,
                variableObject,
                parameterObject,
                functionMap,
                variableIdMap
            )
            updatedVariableObject = parsedBlockValue.variableObject;

            if(blockType === 'field'){
                accumulator[parsedBlockName.value] = parsedBlockValue.value;
            }else{
                accumulator.push(parsedBlockValue.value);
            }
        }
        return {
            value : accumulator,
            variableObject : updatedVariableObject
        };
    }catch(e){
        console.log('/utils/functionParser/scriptObjectParser/scriptObjectParser.js catch block error', e);
        return {
            value : undefined,
            variableObject
        };
    }
}

export default scriptObjectParser;