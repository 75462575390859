import recursiveFunctionStepParser from '../recursiveFunctionStepParser';
import functionResponseObjectExtractor from '../general/functionResponseObjectExtractor';

export default function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
    setStateFunction
){
    try{
        let updatedVariables = variableObject;
        const {
            executionObject={}
        } = functionObj;
        const {
            iterationId='',
            functionObject={},
        } = executionObject;

        const {
            functionSteps=[]
        } = functionObject;
        let normalizedFunctionSteps = [], responseObject={};
        for (let k = 0; k < functionSteps.length; k++){
            let workflow = functionSteps[k];
            let steps = workflow['functionSteps'] || [];
            normalizedFunctionSteps.push(...steps);
        }

        if(navigator.geolocation){
            navigator.geolocation.watchPosition(position => {
                parameterObject[`userPosition_${iterationId}`] = position;
                let functionResponse = recursiveFunctionStepParser(
                    normalizedFunctionSteps, 
                    updatedVariables, 
                    parameterObject, 
                    functionMap,
                    variableIdMap,
                    setStateFunction
                );
                responseObject = functionResponseObjectExtractor(functionResponse);
            })
        }

        return {
            ...responseObject,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : updatedVariables
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/general/functionTypeParser/watchPositionParser catch block error', e)
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}