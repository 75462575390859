import config from '../../config/config';
import NodeRSA from 'node-rsa';

export default function(data, encrypt=true){
    try{
        let publicKey = new NodeRSA(process.env.RAZZLE_CLIENT_PUBLIC_KEY);
        let parsed;
        if(encrypt){
            parsed = publicKey.encrypt(data, 'base64');
        }else{
            parsed = publicKey.decryptPublic(data, 'utf8');
        }
        return parsed;
    }catch(e){
        console.log('encryptDecrypt error', e)
        return data;
    }
}



// export default function(
//     data,
//     password,
//     encrypt=true
// ){
//     try{
//         let parsed;
//         if(encrypt){
//             parsed = window.CryptoJS.AES.encrypt(data, password).toString();
//         }else{
//             let bytes = window.CryptoJS.AES.decrypt(data, password);
//             parsed = bytes.toString(window.CryptoJS.enc.Utf8);
//         }
//         return parsed;
//     }catch(e){
//         console.log('encryptDecrypt error', e)
//         return data;
//     }
// }