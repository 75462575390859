import React from 'react';
import {connect} from 'react-redux';
import {
    setAppViewerReducer
} from '../../../actions';
import WebAppPageViewer from './webAppPageViewer';

const ViewTypeParser = (props={}) => {
    const {
        isWebComponent=false, appId='', appMap={}, activeSession=false,
        setAppDataFunction, viewHeight=0, viewWidth=0,
        workerInstance, historyPushFunction, appEnvironment,
        setElementAppData, setParentAppData
    } = props;
    const appObj = appMap[appId];
    if(appObj){
        const {
            appInFocus={}, App_Data={}
        } = appObj;
        const {
            appType=''
        } = appInFocus;

        return (
            <React.Fragment>
                {
                    ['webApp', 'webComponent', 'emailFunction'].includes(appType) && 
                    <WebAppPageViewer 
                        setAppDataFunction={setAppDataFunction}
                        setElementAppData={setElementAppData}
                        setParentAppData={setParentAppData}
                        appId={appId}
                        isWebComponent={isWebComponent}
                        viewWidth={viewWidth}
                        viewHeight={viewHeight}
                        workerInstance={workerInstance}
                        bulkAttributeWorkerInstance={props.bulkAttributeWorkerInstance}
                        handleCachedAttributeCleanup={props.handleCachedAttributeCleanup}
                        workerInstanceProp={props.workerInstanceProp}
                        bulkAttributeWorkerInstanceProp={props.bulkAttributeWorkerInstanceProp}
                        websiteResourceWorkerInstanceProp={props.websiteResourceWorkerInstanceProp}
                        webComponentWorkerInstanceProp={props.webComponentWorkerInstanceProp}
                        appDataChangeWorkerInstanceProp={props.appDataChangeWorkerInstanceProp}
                        calculateListKeyTrackerWorkerInstanceProp={props.calculateListKeyTrackerWorkerInstanceProp}
                        appLanguage={props.appLanguage}
                        appEnvironment={appEnvironment}
                        historyPushFunction={historyPushFunction}
                        App_Data={App_Data}
                        activeSession={activeSession}
                    />
                }
            </React.Fragment>
        )
    }
    return null;
}

function mapStateToProps(state){
    return {
        appMap : state.AppViewer.appMap
    }
}

export default connect(mapStateToProps, {
    setAppViewerReducer
})(ViewTypeParser);