import resolveExpression from '../simpleScript/expressionParser/resolveExpression';
import update from 'immutability-helper';

async function setMappedVariable (
    field='', value,
    mappingFunctions=[], variableObject={},
    parameterObject={}, functionMap={}, variableIdMap={}
){
    try{
        let varObj = variableObject;
        let varObj2 = variableObject;
        let mapStack = {};
        for (let i = 0; i < mappingFunctions.length; i++){
            let mapObj = mappingFunctions[i];
            let resolvedMap = await resolveExpression(
                mapObj,
                varObj2,
                parameterObject,
                functionMap,
                variableIdMap
            )
            mapStack[i] = resolvedMap.value;
            varObj = resolvedMap.variableObject;
            varObj2 = resolvedMap.variableObject;
        }

        //break up object;
        let baseObj = varObj[field];

        let objectStack2 = {}, objStackKeys=[];

        for (let k = 0; k < Object.keys(mapStack).length; k++){
            let key = Object.keys(mapStack)[k];
            let mapText = `${k}.${mapStack[key]}`;
            let pureMapText = mapStack[key];
            objStackKeys.push(mapText)
            if(
                k === (Object.keys(mapStack).length - 1)
            ){
                objectStack2[mapText] = {
                    isArray : Array.isArray(value),
                    value
                }; 
            }else{
                objectStack2[mapText] = {
                    isArray : Array.isArray(baseObj[pureMapText]),
                    value : baseObj[pureMapText]
                };
                baseObj = baseObj[pureMapText];
            }
        }

        //revere reconstruct the object
        let stack2Length = objStackKeys.length;
        let reconstructedObject = objectStack2[objStackKeys[stack2Length - 1]]['value'];
        for (let i = stack2Length - 2; i >= 0; i--){
            let key = objStackKeys[i];
            let isArray = objectStack2[key]['isArray'];
            if(isArray){
                reconstructedObject = update(objectStack2[key]['value'], {$splice : [[
                    Number(objStackKeys[i + 1].split('.')[1]),
                    1,
                    reconstructedObject
                ]]})
            }else{
                reconstructedObject = {
                    ...objectStack2[key]['value'],
                    [objStackKeys[i + 1].split('.')[1]] : reconstructedObject               
                }
            }
        }
        
        return {
            ...varObj,
            [field] : {
                ...varObj[field],
                [objStackKeys[0].split('.')[1]] : reconstructedObject
            }
        }

    }catch(e){
        console.log('/utils/functionParser/mappingFunction/setMappedVariable', e);
        return variableObject;
    }
}

export default setMappedVariable;