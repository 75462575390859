import encryptDecrypt from '../../cryptography/encryptDecrypt';
import config from '../../../config/config';
import moment from 'moment';
import bulkExpressionParser from '../simpleScript/expressionParser/bulkExpressionParser';
import dotNotationMapper from '../../data/dotNotationMapper';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            variableName='', 
            variableUnavailable=false, 
        } = executionObject;
        const {
            companyId='', inhouz_reserved_parameters_xyzh={}, user={}
        } = parameterObject;
        const {appEnvironment=''} = inhouz_reserved_parameters_xyzh;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let parsedParameters = await bulkExpressionParser({
            executionObject,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap,
            useAllFields : true,
            skipList : ['iterationId']
        });

        if(parsedParameters.error){
            variableObject[variableName] = {
                error : {
                    message : 'Failed to parse function data.'
                }
            }
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Failed to resolve function parameters.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let variableCopy = parsedParameters['variableObject'];
        let resolvedParams = parsedParameters['accumulator'];

        let payload = {
            environment : appEnvironment,
            assetOwnerCompanyId : companyId,
            collectTax : resolvedParams['collectTax'] ? true : false,
            tenantId : resolvedParams['tenantId'] || '',
            projectId : resolvedParams['projectId'] || '',
            metaData : resolvedParams['metaData'] || {},
            currency : resolvedParams['currency'],
            amount : resolvedParams['amount'] || 0,
            isInhouzProducts : resolvedParams['isInhouzProducts'] ? true : false,
            useSystemCalculatedPrice : resolvedParams['useSystemCalculatedPrice'],
            subscriptionServiceId : resolvedParams['subscriptionServiceId'] || '',
            invoiceNumber : resolvedParams['invoiceNumber'],
            invoiceDueDate : resolvedParams['invoiceDueDate'],
            purchaseOrderNumber : resolvedParams['purchaseOrderNumber'],
            vendorMessage : resolvedParams['vendorMessage'] || '',
            queryKey : `${Math.random()}_${Math.random()}_${Math.random()}_${Math.random()}`
        }

        let purchasedItems = [], invalidItems=false;

        for (let i = 0; i < resolvedParams['purchasedItems'].length; i++){
            let item = resolvedParams['purchasedItems'][i];
            let newObj = {
                name : dotNotationMapper(item, resolvedParams['productNameField']),
                quantity : dotNotationMapper(item, resolvedParams['productQuantityField']),
                unitPrice : dotNotationMapper(item, resolvedParams['productUnitPriceField'])
            }

            if(
                !newObj['name'] ||
                !newObj['quantity'] ||
                typeof newObj['name'] !== 'string' ||
                typeof newObj['quantity'] !== 'number'
            ){
                invalidItems = true;
                break;
            }

            if(resolvedParams['isInhouzProducts']){
                newObj['productId'] = dotNotationMapper(item, resolvedParams['productIdField']);
                if(
                    !newObj['productId'] ||
                    typeof newObj['productId'] !== 'string'
                ){
                    invalidItems = true;
                    break;
                }
            }else{
                newObj['price'] = newObj['unitPrice'] && 
                typeof newObj['unitPrice'] === 'number' ?
                (newObj['quantity'] * newObj['unitPrice'])
                :
                0;
            }

            purchasedItems.push(newObj);
        }

        if(invalidItems){
            variableCopy[variableName] = {
                error : {
                    message : 'Invalid purchased items list. Ensure that all required fields are provided with the proper types.'
                }
            }
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Failed to resolve function parameters.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        payload['purchasedItems'] = purchasedItems;

        if(resolvedParams['collectShippingAddress']){
            let shippingAddress = {
                line1 : resolvedParams['shippingAddressLine1'] || '',
                line2 : resolvedParams['shippingAddresLine2'] || '',
                city : resolvedParams['shippingAddressCity'] || '',
                state : resolvedParams['shippingAddressState'] || '',
                country : resolvedParams['shippingAddressCountry'] || '',
                postal : resolvedParams['shippingAddressLine1'] || '',
            }

            payload['shippingAddress'] = shippingAddress;
        }

        let encryptedPayload = encryptDecrypt(
            JSON.stringify({
                payload,
                expirationTimestamp : moment().add(60, 'seconds').unix() * 1000
            }),
            true
        );

        let response = await fetch(
            `${config.devServerProxy}/api/inhouzpay/payment/subscription/ondemand/web`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache'
                },
                body : JSON.stringify({
                    encryptedPayload
                })
            }
        )
        .then(resp => resp.json())
        .catch((error) => {
            return {
                error : {
                    message : error.message
                }
            }
        });

        variableCopy[variableName] = response;
        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/subscriptionOnDemandPayment catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}