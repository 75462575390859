import simpleScriptParser from '../simpleScript/simpleScriptParser';
import recursiveFunctionStepParser from '../recursiveFunctionStepParser';
import functionResponseObjectExtractor from '../general/functionResponseObjectExtractor';
import {isEqual} from 'lodash';

export default function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
    setStateFunction
){
    try{
        let updatedVariables = variableObject;
        let returnObject = {
            updatedVariableObject : updatedVariables,
            hasError : false,
            hasReturnStatement : false,
            returnStatement : undefined
        }
        let {
            executionObject={}
        } = functionObj;
        let {
            switchOn=[], switchArray=[]
        } = executionObject;
        let parsedSwitchOn = simpleScriptParser(
            switchOn,
            updatedVariables,
            parameterObject,
            functionMap,
            variableIdMap
        );
        updatedVariables = parsedSwitchOn.variableObject;
        let executedBlock = {};
        for (let i = 0; i < switchArray.length; i++){
            let switchBlock = switchArray[i];
            let {
                switchCase=[]
            } = switchBlock;

            if(i === switchArray.length - 1){
                executedBlock = switchBlock;
            }else{
                let parsedCase = simpleScriptParser(
                    switchCase,
                    updatedVariables,
                    parameterObject,
                    functionMap,
                    variableIdMap
                );
                if(isEqual(parsedCase.value, parsedSwitchOn.value)){
                    executedBlock = switchBlock;
                    updatedVariables = parsedCase.variableObject;
                    break;
                }
            }
        }

        let {
            functionObject={}
        } = executedBlock;
        const {
            functionSteps=[]
        } = functionObject;
        let normalizedFunctionSteps = [];
        for (let k = 0; k < functionSteps.length; k++){
            let workflow = functionSteps[k];
            let steps = workflow['functionSteps'] || [];
            normalizedFunctionSteps.push(...steps);
        }
        if(!normalizedFunctionSteps.length){
            return returnObject;
        }
        let functionResponse = recursiveFunctionStepParser(
            normalizedFunctionSteps, 
            updatedVariables, 
            parameterObject, 
            functionMap,
            variableIdMap,
            setStateFunction
        );
        returnObject = functionResponseObjectExtractor(functionResponse);
        return returnObject;
    }catch(e){
        console.log('/utils/syncFunctionParser/general/functionTypeParser/switchParser catch block error', e)
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}