import bulkExpressionParser from '../simpleScript/expressionParser/bulkExpressionParser';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
    setStateFunction
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            inhouz_reserved_parameters_xyzh={}
        } = parameterObject;
        const {setElementAppData} = inhouz_reserved_parameters_xyzh;
        if(!setElementAppData){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Element setState function was not found.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let parsedParameters = await bulkExpressionParser({
            executionObject,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap,
            fieldList
        });

        if(parsedParameters.error){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Failed to resolve function parameters.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let variableCopy = parsedParameters['variableObject'];
        let resolvedParams = parsedParameters['accumulator'];

        if(
            typeof resolvedParams['data'] === 'object' && 
            !Array.isArray(resolvedParams['data']) && 
            Array.isArray(resolvedParams['elementIdList']) && 
            resolvedParams['elementIdList'].length > 0
        ){
            for (let i = 0; i < resolvedParams['elementIdList'].length; i++){
                let elementId = resolvedParams['elementIdList'][i];
                if(elementId && typeof elementId === 'string'){
                    setElementAppData({
                        data : resolvedParams['data'],
                        elementId,
                        refresh : true,
                        clearCache : resolvedParams['cacheElementData'] ? false : true
                    });
                }
            }
    
            if((resolvedParams['updateClientAppData'] === true) && setStateFunction){
                setTimeout(() => {
                    setStateFunction(resolvedParams['data']);
                }, typeof resolvedParams['clientAppDataUpdateMS'] === 'number' ? resolvedParams['clientAppDataUpdateMS'] : 500);
            }
        }else{
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Data must be a valid object literal and elementIdList must be a valid array of element IDs.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy,
            hasError : false,
            errorPayload : undefined
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/elementSetStateParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}

const fieldList = [
    'data',
    'elementIdList',
    'updateClientAppData',
    'clientAppDataUpdateMS',
    'cacheElementData'
]