import recursiveFunctionStepParser from '../recursiveFunctionStepParser';
import resolveExpression from '../simpleScript/expressionParser/resolveExpression';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
    setStateFunction
){
    try{
        let updatedVariables = variableObject;
        const {
            executionObject={}
        } = functionObj;
        const {
            timeoutMs={}, functionObject={},
        } = executionObject;
        const {
            functionSteps=[]
        } = functionObject;
        let normalizedFunctionSteps = [];
        for (let k = 0; k < functionSteps.length; k++){
            let workflow = functionSteps[k];
            let steps = workflow['functionSteps'] || [];
            normalizedFunctionSteps.push(...steps);
        }

        let resolvedQuery = await resolveExpression(
            timeoutMs,
            updatedVariables,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let timeoutValue = resolvedQuery.value;
        updatedVariables = resolvedQuery.variableObject;

        setTimeout(function(){
            recursiveFunctionStepParser(
                normalizedFunctionSteps, 
                updatedVariables, 
                parameterObject, 
                functionMap,
                variableIdMap,
                setStateFunction
            )
        }, timeoutValue);

        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : updatedVariables,
            hasError : false,
            errorPayload : undefined
        };
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/setTimeoutParser catch block error', e)
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}