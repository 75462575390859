import validator from 'validator';
import moment from 'moment';
import {isEmpty} from 'lodash';

export default function (variableValue, methodName=''){
    try{
        if(methodName === 'length'){
            return variableValue.length;
        }else if(methodName === 'toLowerCase'){
            return variableValue.toLowerCase();
        }else if(methodName === 'toString'){
            return variableValue.toString();
        }else if(methodName === 'toUpperCase'){
            return variableValue.toUpperCase();
        }else if(methodName === 'trim'){
            return variableValue.trim();
        }else if(methodName === 'toExponential'){
            return variableValue.toExponential();
        }else if(methodName === 'getDate'){
            return new Date(variableValue).getDate();
        }else if(methodName === 'getDay'){
            return new Date(variableValue).getDay();
        }else if(methodName === 'getFullYear'){
            return new Date(variableValue).getFullYear();
        }else if(methodName === 'getHours'){
            return new Date(variableValue).getHours();
        }else if(methodName === 'getMilliseconds'){
            return new Date(variableValue).getMilliseconds();
        }else if(methodName === 'getMinutes'){
            return new Date(variableValue).getMinutes();
        }else if(methodName === 'getMonth'){
            return new Date(variableValue).getMonth();
        }else if(methodName === 'getSeconds'){
            return new Date(variableValue).getSeconds();
        }else if(methodName === 'getTime'){
            return new Date(variableValue).getTime();
        }else if(methodName === 'getTimezoneOffset'){
            return new Date(variableValue).getTimezoneOffset();
        }else if(methodName === 'getUTCDate'){
            return new Date(variableValue).getUTCDate();
        }else if(methodName === 'getUTCDay'){
            return new Date(variableValue).getUTCDay();
        }else if(methodName === 'getUTCFullYear'){
            return new Date(variableValue).getUTCFullYear();
        }else if(methodName === 'getUTCHours'){
            return new Date(variableValue).getUTCHours();
        }else if(methodName === 'getUTCMilliseconds'){
            return new Date(variableValue).getUTCMilliseconds();
        }else if(methodName === 'getUTCMinutes'){
            return new Date(variableValue).getUTCMinutes();
        }else if(methodName === 'getUTCMonth'){
            return new Date(variableValue).getUTCMonth();
        }else if(methodName === 'toDateString'){
            return new Date(variableValue).toDateString();
        }else if(methodName === 'toISOString'){
            return new Date(variableValue).toISOString();
        }else if(methodName === 'toLocaleDateString'){
            return new Date(variableValue).toLocaleDateString();
        }else if(methodName === 'toLocaleTimeString'){
            return new Date(variableValue).toLocaleTimeString();
        }else if(methodName === 'toUTCString'){
            return new Date(variableValue).toUTCString();
        }else if(methodName === 'typeof'){
            return typeof variableValue;
        }else if(methodName === 'isFalsey'){
            return !variableValue;
        }else if(methodName === 'isEmail'){
            return validator.isEmail(variableValue);
        }else if(methodName === 'isBase64'){
            return validator.isBase64(variableValue);
        }else if(methodName === 'isBoolean'){
            return [true, false].includes(typeof variableValue);
        }else if(methodName === 'isDate'){
            return moment(variableValue).isValid();
        }else if(methodName === 'isEmpty'){
            return isEmpty(variableValue);
        }else if(methodName === 'isLowercase'){
            return validator.isLowercase(variableValue);
        }else if(methodName === 'isJWT'){
            return validator.isJWT(variableValue);
        }else if(methodName === 'isIP'){
            return validator.isIP(variableValue);
        }else if(methodName === 'isIpRange'){
            return validator.isIPRange(variableValue);
        }else if(methodName === 'isHexColor'){
            return validator.isHexColor(variableValue);
        }else if(methodName === 'isRgbColor'){
            return validator.isRgbColor(variableValue);
        }else if(methodName === 'isUppercase'){
            return validator.isUppercase(variableValue);
        }else if(methodName === 'isMongoId'){
            return validator.isMongoId(variableValue);
        }else if(methodName === 'isJSON'){
            try{
                let json = JSON.stringify(variableValue);
                if(json){
                    return true;
                }else{
                    return false;
                }
            }catch(e){
                return false;
            }
        }else{
            return variableValue;
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/simpleScript/expressionMethods/methodParser/variableNoParam catch block error', e);
        return variableValue;
    }
}