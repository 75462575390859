import resolveExpression from '../simpleScript/expressionParser/resolveExpression';
import encryptDecrypt from '../../cryptography/encryptDecrypt';
import config from '../../../config/config';
import moment from 'moment';
import dotNotationMapper from '../../data/dotNotationMapper';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        const {
            functionType='',
            executionObject={}
        } = functionObj;
        const {
            variableName='', 
            variableUnavailable=false, 
            dataToEnrich={}, productIdField={}
        } = executionObject;
        const {
            companyId='', inhouz_reserved_parameters_xyzh={}, user={}
        } = parameterObject;
        const {appEnvironment=''} = inhouz_reserved_parameters_xyzh;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(
            !companyId || 
            (
                companyId && 
                typeof companyId !== 'string'
            )
        ){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Account identifier is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let variableCopy = variableObject;
        let resolvedDataToEnrich = await resolveExpression(
            dataToEnrich,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let productList = resolvedDataToEnrich.value;
        variableCopy = resolvedDataToEnrich.variableObject;

        if(
            !Array.isArray(productList)
        ){
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Data to enrich must be an array of objects.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let resolvedProductIdField = await resolveExpression(
            productIdField,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let productIdPath = resolvedProductIdField.value;
        variableCopy = resolvedProductIdField.variableObject;

        if(
            !productIdPath ||
            typeof productIdPath !== 'string'
        ){
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Product ID field or path is required.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let productIdList = [];
        for (let i = 0; i < productList.length; i++){
            let productObj = productList[i];
            let idValue = dotNotationMapper(productObj, productIdPath);
            if(
                idValue && 
                typeof idValue === 'string'
            ){
                productIdList.push(idValue);
            }
        }

        if(productIdList === 0){
            variableCopy[variableName] = productList;
            return {
                hasError : false,
                errorPayload : undefined,
                hasReturnStatement : false,
                returnStatement : undefined,
                updatedVariableObject : variableCopy
            }
        }

        let payload = {
            companyId,
            productIds : productIdList,
            functionType : 'enrichProductData'
        }

        let encryptedPayload = encryptDecrypt(
            JSON.stringify({
                payload,
                expirationTimestamp : moment().add(60, 'seconds').unix() * 1000
            }),
            true
        );

        let response = await fetch(
            `${config.devServerProxy}/api/inhouzcommerce/product/getproductserverquery`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache'
                },
                body : JSON.stringify({
                    encryptedPayload
                })
            }
        )
        .then(resp => resp.json())
        .catch((error) => {
            return {
                error : {
                    message : error.message
                }
            }
        });

        if(response['error']){
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Failed to enrich product data.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let resolvedProducts = response['results'] || [], dataMap={};
        for (let i = 0; i < resolvedProducts.length; i++){
            let productObj = resolvedProducts[i];
            dataMap[productObj['productId']] = productObj;
        }

        let enrichedData = [];
        for (let i = 0; i < productList.length; i++){
            let dataObj = productList[i];
            let productId = dotNotationMapper(dataObj, productIdPath);
            let productObj = dataMap[productId] || {};
            enrichedData.push({
                ...dataObj,
                ...productObj
            });
        }

        variableCopy[variableName] = enrichedData;
        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/enrichProductDataParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}