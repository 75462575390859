import simpleScriptParser from '../simpleScript/simpleScriptParser';
import resolveExpression from '../simpleScript/expressionParser/resolveExpression';
import FormData from 'form-data';
import encryptDecrypt from '../../cryptography/encryptDecrypt';
import moment from 'moment';
import {compress, decompress} from 'shrink-string';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            companyId='',
            apiUrl=[], apiMethod='',
            mode='', bodyContainsFiles=false,
            body={}, params={}, headers={},
            variableName='', variableUnavailable=false
        } = executionObject;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let urlRaw = simpleScriptParser(
            apiUrl,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap
        )
        let url = urlRaw.value;

        let reqBodyRaw = resolveExpression(
            body,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let reqBody = reqBodyRaw.value;
        let parametersRaw = resolveExpression(
            params,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let parameters = parametersRaw.value;
        let headerObjRaw = resolveExpression(
            headers,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let headerObj = headerObjRaw.value;

        if(
            typeof parameters === 'object' && 
            Object.keys(parameters).length > 0 && 
            typeof url === 'string'
        ){
            let parameterKeys = Object.keys(parameters);
            let splitUrl = url.split('?');
            let finalUrl = url;
            if(splitUrl.length === 1){
                finalUrl += '?';
            }
            for (let i = 0; i < parameterKeys.length; i++){
                let key = parameterKeys[i];
                let value = parameters[key];
                if(
                    i === 0 && 
                    splitUrl.length > 1
                ){
                    finalUrl += '&'
                }
                finalUrl += `${key}=${value}`;
                if(i !== parameterKeys.length - 1){
                    finalUrl += '&';
                }
            }
            url = finalUrl;
        }

        if(
            typeof url === 'string' && 
            (
                !url.toLowerCase().startsWith('http://') &&
                !url.toLowerCase().startsWith('https://')
            )
        ){
            url = `https://${url}`;
        }

        let revisedBody;
        if(
            bodyContainsFiles && 
            typeof reqBody === 'object'
        ){
            let form = new FormData();
            for (let k in reqBody){
                let val = reqBody[k];
                form.append(k, val);
            }
            revisedBody = form;
        }else{
            revisedBody = typeof reqBody === 'object' ? 
            JSON.stringify(reqBody)
            :
            reqBody;
        }
        
        let fetchOptions = {
            method : apiMethod,
            credentials : 'omit',
            mode
        }

        if(apiMethod !== 'GET'){
            fetchOptions['body'] = revisedBody;
        }

        if(
            typeof headerObj === 'object' && 
            Object.keys(headerObj).length > 0
        ){
            fetchOptions['headers'] = headerObj;
        }

        let encryptedPayload = encryptDecrypt(
            JSON.stringify({
                payload : {
                    url,
                    fetchOptions,
                    companyId
                },
                expirationTimestamp : moment().add(30, 'seconds').unix() * 1000
            }),
            true
        )

        let compressedPayload = await compress(encryptedPayload);
        const request = await fetch(
            '/api/remotenetworkrequest/parse',
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache',
                },
                credentials : 'omit',
                body : JSON.stringify({encryptedPayload : compressedPayload})
            }
        )
        .then(res => {
            return res.json();
        })
        .catch((error) => {
            console.log('remote network request fetch catch block error', error);
            return {
                error : {
                    message : error.message
                }
            };
        });

        let variableCopy = variableObject, responsePayload={};
        if(request['error']){
            variableCopy[variableName] = request;
        }else{
            const {encryptedResponse=''} = request;
            let decompressed = await decompress(encryptedResponse);
            let decryptedPayload = encryptDecrypt(decompressed, false); 
            let parsedPayload = JSON.parse(decryptedPayload);
            if(new Date().getTime() <= parsedPayload['expirationTimestamp']){
                responsePayload = parsedPayload['payload'];
            }else{
                responsePayload = {
                    error : {
                        message : 'Response timed out.'
                    }
                }
            }
            variableCopy[variableName] = responsePayload;
        }
        
        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy,
            hasError : responsePayload.error || request.error ? true : false,
            errorPayload : responsePayload.error || request.error ? responsePayload.error ? responsePayload : request : undefined
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/general/functionTypeParser/remoteNetworkRequestParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}