import resolveExpression from '../../expressionParser/resolveExpression';
import {isEqual} from 'lodash';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

export default function (
    currentValue, method={},
    variableObject, parameterObject={},
    functionMap={}, variableIdMap={}
){
    try{
        const {
            methodData={}, methodName=''
        } = method;

        let param = resolveExpression(
            methodData, variableObject,
            parameterObject, functionMap,
            variableIdMap
        ), returnValue;

        if(methodName === 'charAt'){
            returnValue = currentValue.charAt(param.value);
        }else if(methodName === 'includes'){
            returnValue = currentValue.includes(param.value);
        }else if(methodName === 'indexOf'){
            returnValue = currentValue.indexOf(param.value);
        }else if(methodName === 'localeCompare'){
            returnValue = currentValue.localCompare(param.value);
        }else if(methodName === 'match'){
            returnValue = currentValue.match(param.value);
        }else if(methodName === 'search'){
            returnValue = currentValue.search(param.value);
        }else if(methodName === 'split'){
            returnValue = currentValue.split(param.value);
        }else if(methodName === 'startsWith'){
            returnValue = currentValue.startsWith(param.value);
        }else if(methodName === 'toFixed'){
            returnValue = currentValue.toFixed(param.value);
        }else if(methodName === 'toPrecision'){
            returnValue = currentValue.toPrecision(param.value);
        }else if(methodName === 'setDate'){
            returnValue = new Date(currentValue).setDate(param.value);
        }else if(methodName === 'setMilliseconds'){
            returnValue = new Date(currentValue).setMilliseconds(param.value);
        }else if(methodName === 'setTime'){
            returnValue = new Date(currentValue).setTime(param.value);
        }else if(methodName === 'setUTCDate'){
            returnValue = new Date(currentValue).setUTCDate(param.value);
        }else if(methodName === 'setUTCMilliseconds'){
            returnValue = new Date(currentValue).setUTCMilliseconds(param.value);
        }else if(methodName === 'toLocaleString'){
            returnValue = new Date(currentValue).toLocaleString(param.value);
        }else if(methodName === 'isEqual'){
            returnValue = isEqual(currentValue, param.value);
        }else if(methodName === 'formatDate'){
            returnValue = moment(currentValue).format(param.value);
        }else if(methodName === 'dateToTimezoneMs'){
            returnValue = momentTimezone.tz(currentValue, param.value).unix() * 1000;
        }else if(methodName === 'concat'){
            returnValue = currentValue.concat(
                param.value
            );
        }

        return {
            value : returnValue,
            variableObject : param.variableObject
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/simpleScript/expressionMethods/methodParser/variableNoCustomParamParser catch block error', e);
        return {
            value : undefined,
            variableObject,
            hasError : true,
            errorPayload : e
        };
    }
}