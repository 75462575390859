import resolveExpression from '../simpleScript/expressionParser/resolveExpression';
import resizeImg from 'resize-img';
import * as bufferFrom from 'buffer-from';
import { isBuffer } from 'lodash';
import Jimp from 'jimp';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            width={}, variableName='', variableUnavailable=false,
            imageBuffer={}, height={}, format={}
        } = executionObject;
        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }
        let variableCopy = JSON.parse(JSON.stringify(variableObject));
        let resolvedData = await resolveExpression(
            imageBuffer,
            JSON.parse(JSON.stringify(variableCopy)),
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedImage;
        let imageValue = resolvedData.value;
        if(typeof imageValue === 'object' && !Array.isArray(imageValue)){
            resolvedImage = (imageValue && imageValue.data) || imageValue;
        }else{
            resolvedImage = imageValue;
        }
        // if(resolvedImage && !isBuffer(resolvedImage)){
        //     resolvedImage = bufferFrom(resolvedImage);
        // }
        
        variableCopy = JSON.parse(JSON.stringify(resolvedData.variableObject));
        let resolvedHeightData = await resolveExpression(
            height,
            JSON.parse(JSON.stringify(variableCopy)),
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedHeight = resolvedHeightData.value;
        variableCopy = JSON.parse(JSON.stringify(resolvedHeightData.variableObject));
        let resolveWidthData = await resolveExpression(
            width,
            JSON.parse(JSON.stringify(variableCopy)),
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedWidth = resolveWidthData.value;
        variableCopy = JSON.parse(JSON.stringify(resolveWidthData.variableObject));
        let resolvedFormatData = await resolveExpression(
            format,
            JSON.parse(JSON.stringify(variableCopy)),
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedFormat = resolvedFormatData.value;
        console.log('resolvedFormat', resolvedImage)
        variableCopy = JSON.parse(JSON.stringify(resolvedFormatData.variableObject));
        let response = await resizeImg(resolvedImage, {
            height : resolvedHeight,
            width : resolvedWidth,
            // format : resolvedFormat
        });
        // let response = await Jimp.read(resolvedImage)
        // .then(image => {
        //   // Do stuff with the image.
        //   console.log('image', image)
        //   image.resize(resolvedWidth, resolvedHeight)
        // })
        // .catch(err => {
        //     console.log('err', err)
        //   return err
        // });
        // let response = await new Jimp({ data: resolvedImage, width: 1280, height: 768 }, (err, image) => {
        //     console.log('image', image);
        //     console.log('error', err)
        //     return image;
        // });
        variableCopy[variableName] = response;
        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy,
            hasError : false,
            errorPayload : undefined
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/base64Parser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}