import { combineReducers } from 'redux';
import AppViewer from './appViewer/appViewerReducer';
import SubscriptionAuth from './subscriptionService/subscriptionAuthReducer';
import Multimedia from './multimedia/multimediaReducer';
import UiComponent from './appViewer/uiComponentReducer';

const rootReducer = combineReducers({
  AppViewer,
  SubscriptionAuth,
  Multimedia,
  UiComponent
});

export default rootReducer;
