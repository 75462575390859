import postRequest from '../../../requests/postRequest';

export default async function(obj={}){
    try{
        let response = await postRequest(
            `/api/function/getfunction`,
            'POST',
            obj
        );
        return response;
    }catch(e){
        console.log('/utils/functionBuilder/function/getExternalFunction catch block error', e);
        return {
            error : {
                message : 'An error occured while registering the operation',
                errorPayload : e
            }
        }
    }
}