import resolveExpression from '../simpleScript/expressionParser/resolveExpression';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
){
    try{
        const {
            functionType='',
            executionObject={}
        } = functionObj;
        const {
            variableName='', variableUnavailable=false,
            key={}, value={}
        } = executionObject;
        if(
            (!variableName || 
            variableUnavailable) && 
            ['getSessionStorage', 'getLocalStorage'].includes(functionType)
        ){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }
        let variableCopy = variableObject;
        let resolvedData = await resolveExpression(
            key,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let resolvedKey = resolvedData.value;
        variableCopy = resolvedData.variableObject;
        let resolvedValueObj, resolvedValue;
        if(
            ![
                'removeSessionStorage', 'removeLocalStorage',
                'getLocalStorage', 'getSessionStorage'
            ].includes(functionType)
        ){
            resolvedValueObj = await resolveExpression(
                value,
                variableCopy,
                parameterObject,
                functionMap,
                variableIdMap
            );
            resolvedValue = resolvedValueObj.value;
            variableCopy = resolvedValueObj.variableObject;
        }

        let getData;
        if(functionType === 'setLocalStorage'){
            localStorage.setItem(resolvedKey, resolvedValue);
        }else if(functionType === 'setSessionStorage'){
            sessionStorage.setItem(resolvedKey, resolvedValue);
        }else if(functionType === 'getLocalStorage'){
            getData = localStorage.getItem(resolvedKey);
        }else if(functionType === 'getSessionStorage'){
            getData = sessionStorage.getItem(resolvedKey);
        }else if(functionType === 'removeLocalStorage'){
            localStorage.removeItem(resolvedKey);
        }else if(functionType === 'removeSessionStorage'){
            sessionStorage.removeItem(resolvedKey);
        }

        if(
            [
                'getLocalStorage', 'getSessionStorage'
            ].includes(functionType)
        ){
            variableCopy[variableName] = getData;
        }
        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy,
            hasError : false,
            errorPayload : undefined
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/browserStorageParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}