import resolveExpression from '../simpleScript/expressionParser/resolveExpression';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            querySelector={}, 
            property={}, newValue={}
        } = executionObject;
        
        let variableCopy = variableObject;
        let resolvedSelector = await resolveExpression(
            querySelector,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let selector = resolvedSelector.value;
        if(
            !selector ||
            typeof selector !== 'string' ||
            (
                typeof selector === 'string' && 
                !['.', '#'].includes(selector.charAt(0))
            )
        ){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Invalid query selector.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }
        variableCopy = resolvedSelector.variableObject;
        let resolvedPropertyData = await resolveExpression(
            property,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let propertyValue = resolvedPropertyData.value;
        variableCopy = resolvedPropertyData.variableObject;
        if(typeof propertyValue !== 'string'){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Invalid element property.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }else{
            let resolvedValueData = await resolveExpression(
                newValue,
                variableCopy,
                parameterObject,
                functionMap,
                variableIdMap
            );
            let resolvedValue = resolvedValueData.value;
            variableCopy = resolvedValueData.variableObject;
            if(typeof document !== 'undefined'){
                document.querySelector(selector)[propertyValue] = resolvedValue;
            }
            return {
                hasReturnStatement : false,
                returnStatement : undefined,
                updatedVariableObject : variableCopy,
                hasError : false,
                errorPayload : undefined
            }
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/base64Parser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}