import resolveExpression from '../simpleScript/expressionParser/resolveExpression'

async function recursiveMapper(
    rootMapValue={},
    mappingFunctions=[],
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        let currentMapValue = rootMapValue;
        let updatedVariableObject = variableObject;
        for (let i = 0; i < mappingFunctions.length; i++){
            let mapObject = mappingFunctions[i];
            let resolvedMap = await resolveExpression(
                mapObject,
                updatedVariableObject,
                parameterObject,
                functionMap,
                variableIdMap
            )
            let value = resolvedMap.value;
            updatedVariableObject = resolvedMap.variableObject;
            if(typeof currentMapValue[value] === 'object'){
                currentMapValue = currentMapValue[value];
            }else{
                currentMapValue = currentMapValue[value];
                break;
            }
        }
        return {
            value : currentMapValue,
            variableObject : updatedVariableObject
        };
    }catch(e){
        console.log('/utils/functionParser/mappingFunction/parseMappingFunctions catch block error', e);
        return {
            value : undefined,
            variableObject
        };
    }
}

export default recursiveMapper;

const isJson = (obj) => {
    try{
        return JSON.stringify(obj)
    }catch(e){
        return false;
    }
}