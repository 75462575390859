import variableNoParamParser from './methodParsers/variableNoParamParser';
import variableNoCustomParamParser from './methodParsers/variableNoCustomParamParser';
import variableCustomParamParser from './methodParsers/variableCustomParamParser';
import globalMethodParser from './methodParsers/globalMethodParser';
import numberMethodParser from './methodParsers/numberMethodParser';
import mathMethodParser from './methodParsers/mathMethodParser';
import arrayMethodParser from './methodParsers/arrayMethodParser';

export default function(
    data, methods=[],
    variableObject={}, parameterObject={},
    functionMap={}, variableIdMap={}
){
    try{
        let returnValue = data;
        let updatedVariableObject = variableObject;
        for (let i = 0; i < methods.length; i++){
            const method = methods[i];
            const {
                methodType='', hasParam=false, methodName='',
                customParam=false
            } = method;
            if(methodType === 'variable'){
                if(!hasParam){
                    returnValue = variableNoParamParser(returnValue, methodName);
                }else{
                    if(!customParam){
                        let response = variableNoCustomParamParser(
                            returnValue, method,
                            variableObject, parameterObject,
                            functionMap, variableIdMap
                        );
                        returnValue = response.value;
                        updatedVariableObject = response.variableObject;
                    }else{
                        let response = variableCustomParamParser(
                            returnValue, method,
                            variableObject, parameterObject,
                            functionMap
                        );
                        returnValue = response.value;
                        updatedVariableObject = response.variableObject;
                    }
                }
            }else if(methodType === 'global'){
                returnValue = globalMethodParser(
                    returnValue, method
                )
            }else if(methodType === 'number'){
                returnValue = numberMethodParser(
                    returnValue, method
                )
            }else if(methodType === 'math'){
                let response = mathMethodParser(
                    returnValue, method, variableObject,
                    parameterObject, functionMap, variableIdMap
                );
                returnValue = response.value;
                updatedVariableObject = response.variableObject;
            }else if(methodType === 'array'){
                let response = arrayMethodParser(
                    returnValue, method,
                    variableObject, parameterObject,
                    functionMap, variableIdMap
                );
                returnValue = response.value;
                updatedVariableObject = response.variableObject;
            }
        }
        return {
            value : returnValue,
            variableObject : updatedVariableObject
        };
    }catch(e){
        console.log('/utils/syncFunctionParser/simpleScript/expressionMethods/parseExpressionMethods catch block error', e);
        return {
            value : undefined,
            variableObject
        };
    }
}