import simpleScriptParser from '../simpleScript/simpleScriptParser';
import recursiveFunctionStepParser from '../recursiveFunctionStepParser';
import functionResponseObjectExtractor from '../general/functionResponseObjectExtractor';

export default function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
    setStateFunction
){
    try{
        let updatedVariables = variableObject;
        const {
            executionObject={}
        } = functionObj;
        const {
            condition=[], functionObject={},
        } = executionObject;

        let parsedCondition = simpleScriptParser(
            condition,
            updatedVariables,
            parameterObject,
            functionMap,
            variableIdMap
        );
        updatedVariables = parsedCondition.variableObject;

        const {
            functionSteps=[]
        } = functionObject;
        let normalizedFunctionSteps = [], responseObject={};
        for (let k = 0; k < functionSteps.length; k++){
            let workflow = functionSteps[k];
            let steps = workflow['functionSteps'] || [];
            normalizedFunctionSteps.push(...steps);
        }

        while(
            parsedCondition.value
        ){
            let functionResponse = recursiveFunctionStepParser(
                normalizedFunctionSteps, 
                updatedVariables, 
                parameterObject, 
                functionMap,
                variableIdMap,
                setStateFunction
            );
            responseObject = functionResponseObjectExtractor(functionResponse);
            const {
                hasError=false, updatedVariableObject={},
                breakLoop=false, continueLoop=false
            } = responseObject;
            updatedVariables = updatedVariableObject;

            //re-trigger condition
            parsedCondition = simpleScriptParser(
                condition,
                updatedVariables,
                parameterObject,
                functionMap,
                variableIdMap
            );
            updatedVariables = parsedCondition.variableObject;

            if(breakLoop || hasError){
                break;
            }else if(continueLoop){
                continue;
            }
        }

        return {
            ...responseObject,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : updatedVariables,
            breakLoop : false,
            continueLoop : false
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/general/functionTypeParser/whileLoopParser catch block error', e)
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}