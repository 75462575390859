import resolveExpression from '../../expressionParser/resolveExpression';

export default async function(
    currentValue, method={},
    variableObject={}, parameterObject={},
    functionMap={}, variableIdMap={}
){
    try{
        const {
            methodName='', methodData={}
        } = method;

        let returnValue, updatedVariableObject = variableObject;
        if(methodName === 'abs'){
            returnValue = Math.abs(currentValue);
        }else if(methodName === 'acos'){
            returnValue = Math.acos(currentValue);
        }else if(methodName === 'acosh'){
            returnValue = Math.acosh(currentValue);
        }else if(methodName === 'asin'){
            returnValue = Math.asin(currentValue);
        }else if(methodName === 'asinh'){
            returnValue = Math.asinh(currentValue);
        }else if(methodName === 'atan'){
            returnValue = Math.atan(currentValue);
        }else if(methodName === 'cbrt'){
            returnValue = Math.cbrt(currentValue);
        }else if(methodName === 'ceil'){
            returnValue = Math.ceil(currentValue);
        }else if(methodName === 'cos'){
            returnValue = Math.cos(currentValue);
        }else if(methodName === 'cosh'){
            returnValue = Math.cosh(currentValue);
        }else if(methodName === 'floor'){
            returnValue = Math.floor(currentValue);
        }else if(methodName === 'log'){
            returnValue = Math.log(currentValue);
        }else if(methodName === 'round'){
            returnValue = Math.round(currentValue);
        }else if(methodName === 'sin'){
            returnValue = Math.sin(currentValue);
        }else if(methodName === 'sqrt'){
            returnValue = Math.sqrt(currentValue);
        }else if(methodName === 'tan'){
            returnValue = Math.tan(currentValue);
        }else if(methodName === 'tanh'){
            returnValue = Math.tanh(currentValue);
        }else if(methodName === 'trunc'){
            returnValue = Math.trunc(currentValue);
        }else if(methodName === 'random'){
            returnValue = Math.random();
        }else if(methodName === 'exp'){
            returnValue = Math.exp(currentValue);
        }else if(methodName === 'atan2'){
            let x = await resolveExpression(
                methodData['x'], updatedVariableObject,
                parameterObject, functionMap
            )
            updatedVariableObject = x.variableObject;
            let y = await resolveExpression(
                methodData['y'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = y.variableObject;
            returnValue = await Math.atan2(
                x.value,
                y.value
            );
        }else if(methodName === 'max'){
            let x = await resolveExpression(
                methodData['x'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            )
            updatedVariableObject = x.variableObject;
            let y = await resolveExpression(
                methodData['y'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            updatedVariableObject = y.variableObject;
            returnValue = await Math.max(
                x.value,
                y.value
            );
        }else if(methodName === 'min'){
            let x = await resolveExpression(
                methodData['x'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            )
            updatedVariableObject = x.variableObject;
            let y = await resolveExpression(
                methodData['y'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            returnValue = await Math.min(
                x.value,
                y.value
            );
        }else if(methodName === 'pow'){
            let x = await resolveExpression(
                methodData['power'], updatedVariableObject,
                parameterObject, functionMap, variableIdMap
            )
            updatedVariableObject = x.variableObject;
            returnValue = await Math.pow(
                currentValue,
                x.value
            );
        }else{
            returnValue = currentValue;
        }

        return {
            value : returnValue,
            variableObject : updatedVariableObject
        }
    }catch(e){
        console.log('/utils/functionParser/simpleScript/expressionMethods/methodParser/mathMethodParser catch block error', e);
        return {
            value : undefined,
            variableObject,
            hasError : true
        };
    }
}