import resolveExpression from '../simpleScript/expressionParser/resolveExpression';

export default function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={},
){
    try{
        const {
            functionType='',
            executionObject={}
        } = functionObj;
        const {
            querySelector={}, variableName='', variableUnavailable=false,
            property={}
        } = executionObject;
        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }
        let variableCopy = variableObject;
        let resolvedSelector = resolveExpression(
            querySelector,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let selector = resolvedSelector.value;
        if(
            !selector ||
            typeof selector !== 'string' ||
            (
                typeof selector === 'string' && 
                !['.', '#'].includes(selector.charAt(0))
            )
        ){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Invalid query selector.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }
        variableCopy = resolvedSelector.variableObject;
        let resolvedProperyData = resolveExpression(
            property,
            variableCopy,
            parameterObject,
            functionMap,
            variableIdMap
        );
        let propertyValue = resolvedProperyData.value;
        variableCopy = resolvedProperyData.variableObject;
        if(typeof document !== 'undefined'){
            if(typeof propertyValue !== 'string'){
                variableCopy[variableName] = document.querySelector(selector);
            }else{
                if(functionType === 'getDomProperty'){
                    variableCopy[variableName] = document.querySelector(selector) && 
                    document.querySelector(selector)[propertyValue];
                }else{
                    if(
                        typeof (document.querySelector(selector) && 
                        document.querySelector(selector)[propertyValue]) === 'function'
                    ){
                        variableCopy[variableName] = document.querySelector(selector) && 
                        document.querySelector(selector)[propertyValue]()
                    }else{
                        variableCopy[variableName] = document.querySelector(selector) && 
                        document.querySelector(selector)[propertyValue];
                    }
                }
            }
        }
        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy,
            hasError : false,
            errorPayload : undefined
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/general/functionTypeParser/base64Parser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}