export default function(
    functionObj={},
    variableObject={},
    parameterObject={}
){
    try{
        const {
            functionType=''
        } = functionObj;
        let event = parameterObject['event'];
        if(functionType === 'eventPreventDefault'){
            if(
                event && event.preventDefault 
                && typeof event.preventDefault === 'function'
            ){
                event.preventDefault();
            }
        }else{
            if(
                event && event.stopPropagation 
                && typeof event.stopPropagation === 'function'
            ){
                event.stopPropagation();
            }
        }
        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableObject,
            hasError : false,
            errorPayload : undefined
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/general/functionTypeParser/eventBasicMethodParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}