import resolveExpression from "../../../../../../utils/syncFunctionParser/simpleScript/expressionParser/resolveExpression";

export default function(functionParseParams={}){
    try{
        let {
            element,
            companyId='',
            user={},
            userAgent={},
            App_Data={},
            functionMap={},
            otherParameters={},
            setStateFunction
        } = functionParseParams;

        let elementSettings = element['elementSettings'] || {};
        let attributes = elementSettings['attributes'] || {};
        let customAttributes = elementSettings['customAttributes'] || [];
        let consolidatedAttributes = {
            ...attributes
        };

        for (let i = 0; i < customAttributes.length; i++){
            let customAttribute = customAttributes[i];
            let {attributeName='', value={}} = customAttribute;
            consolidatedAttributes[attributeName] = value;

            customAttribute = null;
            attributeName = null;
            value = null;
        }

        let accumulator = {};
        let parameters = {
            ...otherParameters,
            App_Data,
            ENV : (App_Data['appSettings'] && App_Data['appSettings']['ENV']) || {},
            user,
            userAgent,
            companyId
        }
        for (let k in consolidatedAttributes){
            let expression = consolidatedAttributes[k];
            let parsedExpression = resolveExpression(
                expression,
                {},
                parameters,
                functionMap,
                {},
                setStateFunction
            );

            accumulator[k] = parsedExpression.value;
            expression = null;
            parsedExpression.value = null;
            parsedExpression.variableObject = null;
            parsedExpression = null;
        }

        functionParseParams = null; 
        elementSettings=null;
        attributes=null; 
        customAttributes=null; 
        for (let k in consolidatedAttributes){
            consolidatedAttributes[k] = null;
        }
        consolidatedAttributes=null;
        for (let k in parameters){
            parameters[k] = null;
        }
        parameters=null; 
        element=null; 
        companyId=''; 
        user=null; 
        userAgent=null;
        App_Data=null; 
        functionMap=null; 
        otherParameters=null; 
        setStateFunction=null;
        return accumulator;
    }catch(e){
        console.log('/appViewer/utils/functions/attributes/elementAttributeFunction catch error', e);
        functionParseParams = null;
        return {};
    }
}