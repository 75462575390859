export default function(responseObject={}, index){
    try{
        let responseKeys = Object.keys(responseObject)
        let stepCount = responseKeys.length;
        let finalIndex = typeof index === 'number' ? index : stepCount - 1;
        let extractedResponseObject = {};
        for (let i = 0; i < responseKeys.length; i++){
            let key = responseKeys[i];
            let split = key.split('.');
            if(
                Number(split[1]) === finalIndex
            ){
                extractedResponseObject = responseObject[key];
                break;
            }
        }

        return extractedResponseObject;
    }catch(e){  
        console.log('/utils/syncFunctionParser/general/functionResponseObjectExtractor')
        return undefined;
    }
}