import {
    isEmpty, cloneDeep
} from 'lodash';
import recursiveFunctionStepParser from './recursiveFunctionStepParser';
import resolveExpression from './simpleScript/expressionParser/resolveExpression';

export default function(
    functionObject={}, 
    parameterObj={}, 
    setStateFunction,
    functionMap,
    mappedVariables={}
){
    try{
        const {
            variables=[], parameters=[], functionSteps=[]
        } = functionObject;
        let variableMap = cloneDeep(mappedVariables), parameterObject = parameterObj;

        let variableIdMap={
            variable : {},
            parameter : {},
            state : {}
        };
        //initialize variables
        if(
            !mappedVariables ||
            isEmpty(mappedVariables)
        ){
            for (let i = 0; i < variables.length; i++){
                let variableObject = variables[i];
                let {
                    variableName='', variableDefaultValue,
                    variableId=''
                } = variableObject;
                variableMap[variableName] = variableDefaultValue;
                variableIdMap['variable'][variableId] = variableName;
            }
        }

        for (let i = 0; i < parameters.length; i++){
            let parameter = parameters[i];
            const {
                parameterName='', parameterDefaultValue,
                parameterId=''
            } = parameter;
            variableIdMap['parameter'][parameterId] = parameterName;
            if(!parameterObj[parameterName]){
                let resolvedExpression = resolveExpression(parameterDefaultValue);
                parameterObject[parameterName] = resolvedExpression.value;
            }
        }

        //normalize workflows into functionSteps
        let normalizedFunctionSteps = [];
        for (let i = 0; i < functionSteps.length; i++){
            let workflow = functionSteps[i];
            let steps = workflow['functionSteps'] || [];
            normalizedFunctionSteps.push(...steps);
        }

        let functionResponse = recursiveFunctionStepParser(
            normalizedFunctionSteps, 
            variableMap, 
            parameterObject, 
            functionMap,
            variableIdMap,
            setStateFunction
        );
        return functionResponse;
    }catch(e){
        console.log('/utils/syncFunctionParser/functionParser catch block error', e);
        return {
            error : {
                errorPayload : e
            }
        };
    }
}