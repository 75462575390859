import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import 'core-js';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import configureStore from '../common/store/configureStore';
import App from '../common/containers/App';

import 'bootstrap/dist/js/bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
// import 'easymde/dist/easymde.min.css';
import "../common/css/style.css";
import "../common/css/markDownEditor.css";
// import "@carbon/charts/styles.css";
// import 'mapbox-gl/dist/mapbox-gl.css';

const store = configureStore(window.__PRELOADED_STATE__);

hydrate(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept('../common/containers/App', () => {
    hydrate(
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>,
      document.getElementById('root')
    );
  });
}
