import bulkExpressionParser from '../simpleScript/expressionParser/bulkExpressionParser';
import { clear, del, delMany, get, getMany, keys, set, setMany } from 'idb-keyval';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        const {
            functionType='',
            executionObject={}
        } = functionObj;
        const {
            variableName='', 
            variableUnavailable=false, 
        } = executionObject;

        if(
            [
                'indexedDbGet', 'indexedDbGetMany',
                'indexedDbGetKeys'
            ].includes(functionType) && 
            (!variableName || variableUnavailable)
        ){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let variableCopy = variableObject;
        let parsedParameters = await bulkExpressionParser({
            executionObject,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap,
            useAllFields : true
        });

        if(parsedParameters.error){
            if(
                [
                    'indexedDbGet', 'indexedDbGetMany',
                    'indexedDbGetKeys'
                ].includes(functionType)
            ){
                variableCopy[variableName] = {
                    error : {
                        message : 'Failed to parse function parameters.'
                    }
                }
            }
            return {
                updatedVariableObject : variableCopy,
                hasError : true,
                errorPayload : 'Failed to resolve function parameters.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        variableCopy = parsedParameters['variableObject'];
        let resolvedParams = parsedParameters['accumulator'];

        if(functionType === 'indexedDbSet'){
            await set(resolvedParams['key'], resolvedParams['value']);
        }else if(functionType === 'indexedDbGet'){
            variableCopy[variableName] = await get(resolvedParams['key']);
        }else if(functionType === 'indexedDbSetMany'){
            await setMany(resolvedParams['keyValueArray']);
        }else if(functionType === 'indexedDbGetMany'){
            variableCopy[variableName] = await getMany(resolvedParams['keys']);
        }else if(functionType === 'indexedDbDelete'){
            await del(resolvedParams['key']);
        }else if(functionType === 'indexedDbDeleteMany'){
            await delMany(resolvedParams['keys']);
        }else if(functionType === 'indexedDbClear'){
            await clear();
        }else if(functionType === 'indexedDbGetKeys'){
            variableCopy[variableName] = await keys();
        }

        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/indexedDbParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.message,
            hasReturnStatement : false,
            returnStatement : undefined
        } 
    }
}