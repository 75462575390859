import sanitizer from 'sanitizer';
import {recursiveSanitizer} from '../../../../sanitizer/recursiveSanitizer';
import queryString from 'query-string';

export default function(
    currentValue, method={}
){
    try{
        const {
            methodName=''
        } = method;

        if(methodName === 'isNaN'){
            return isNaN(currentValue);
        }else if(methodName === 'JSON.parse'){
            return JSON.parse(currentValue);
        }else if(methodName === 'JSON.stringify'){
            return JSON.stringify(currentValue);
        }else if(methodName === 'x-www-form-urlencoded'){
            return queryString.stringify(currentValue);
        }else if(methodName === 'decodeURI'){
            return decodeURI(currentValue);
        }else if(methodName === 'decodeURIComponent'){
            return decodeURIComponent(currentValue);
        }else if(methodName === 'encodeURI'){
            return encodeURI(currentValue);
        }else if(methodName === 'encodeURIComponent'){
            return encodeURIComponent(currentValue);
        }else if(methodName === 'Number'){
            return Number(currentValue);
        }else if(methodName === 'sanitizer'){
            return recursiveSanitizer(currentValue, sanitizer);
        }else if(methodName === 'newDate'){
            if(currentValue){
                return new Date(currentValue);
            }else{
                return new Date();
            }
        }else {
            return currentValue;
        }
    }catch(e){
        console.log('/utils/functionParser/simpleScript/expressionMethods/methodParser/globalMethodParser catch block error', e);
        return undefined;
    }
}