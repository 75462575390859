import encryptDecrypt from '../../cryptography/encryptDecrypt';
import config from '../../../config/config';
import moment from 'moment';
import bulkExpressionParser from '../simpleScript/expressionParser/bulkExpressionParser';

export default async function(
    functionObj={},
    variableObject={},
    parameterObject={},
    functionMap={},
    variableIdMap={}
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            variableName='', 
            variableUnavailable=false, 
        } = executionObject;
        const {
            companyId='', user={}
        } = parameterObject;

        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let parsedParameters = await bulkExpressionParser({
            executionObject,
            variableObject,
            parameterObject,
            functionMap,
            variableIdMap,
            useAllFields : true
        });

        if(parsedParameters.error){
            variableObject[variableName] = {
                error : {
                    message : 'Failed to parse function parameters.'
                }
            }
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Failed to resolve function parameters.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let variableCopy = parsedParameters['variableObject'];
        let resolvedParams = parsedParameters['accumulator'];

        let payload = {
            companyId,
            fileStorageServiceId : resolvedParams['fileStorageServiceId'] || ''
        }

        if(resolvedParams['multiple']){
            let keyList = [];
            for (let i = 0; i < resolvedParams['fileKeyList'].length; i++){
                let key = resolvedParams['fileKeyList'][i];
                if(
                    key && 
                    typeof key === 'string'
                ){
                    keyList.push({
                        fileKey : key,
                        companyId
                    });
                }
            }

            payload['fileKeyList'] = keyList;
        }else{
            if(
                resolvedParams['fileKey'] && 
                typeof resolvedParams['fileKey'] === 'string'
            ){
                payload['fileKeyList'] = [{
                    fileKey : resolvedParams['fileKey'],
                    companyId
                }]
            }
        }

        if(
            !payload['fileStorageServiceId'] ||
            typeof payload['fileStorageServiceId'] !== 'string'
        ){
            variableObject[variableName] = {
                error : {
                    message : 'File storage service ID is required.'
                }
            }
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'File storage service ID is required.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        if(payload['fileKeyList'].length === 0){
            variableObject[variableName] = {
                error : {
                    message : resolvedParams['multiple'] ? 
                    'File key list is empty.'
                    :
                    'File key is required.'
                }
            }
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : resolvedParams['multiple'] ? 
                'File key list is empty.'
                :
                'File key is required.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }

        let encryptedPayload = encryptDecrypt(
            JSON.stringify({
                payload,
                expirationTimestamp : moment().add(600, 'seconds').unix() * 1000
            }),
            true
        );

        let response = await fetch(
            `${config.devServerProxy}/api/multimedia/deletefiles`,
            {
                method : 'POST',
                headers : {
                    'Accept':"application/json",
                    'Content-Type':"application/json",
                    'Cache': 'no-cache'
                },
                credentials : 'omit',
                body : JSON.stringify({
                    encryptedPayload
                })
            }
        )
        .then(resp => resp.json())
        .catch((error) => {
            return {
                error : {
                    message : error.message
                }
            }
        });

        variableCopy[variableName] = response;
        return {
            hasError : false,
            errorPayload : undefined,
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/fileStorageDeleteParser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        } 
    }
}