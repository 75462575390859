import resolveExpression from '../../../expressionParser/resolveExpression';
import {orderBy} from 'lodash';

export default function (
    arrayValue, methodData=[], variableObject={},
    parameterObject={}, functionMap={}, variableIdMap={}
    // setStateFunction
){
    try{
        let fieldList = [], orderTypeList = [];
        let upVariableObject = JSON.parse(JSON.stringify(variableObject));

        for (let i = 0; i < methodData.length; i++){
            let data = methodData[i];
            let {
                field=[], orderType=''
            } = data;
            let resolvedField = resolveExpression(
                field, upVariableObject,
                parameterObject, functionMap, variableIdMap
            );
            upVariableObject = JSON.parse(JSON.stringify(resolvedField.variableObject));
            fieldList.push(resolvedField.value);
            orderTypeList.push(orderType)
        }
        let arrayList = orderBy(arrayValue, fieldList, orderTypeList);

        return {
            value : arrayList,
            variableObject : upVariableObject
        }
    }catch(e){
        console.log('/utils/syncFunctionParser/simpleScript/expressionMethods/methodParsers/functionBased/compareSortMethodParser', e);
        return {
            value : undefined,
            variableObject,
            hasError : true,
            errorPayload : e
        };
    }
}