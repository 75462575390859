export default async function(
    functionObj={},
    variableObject={},
    parameterObject={}
){
    try{
        const {
            executionObject={}
        } = functionObj;
        const {
            variableName='', variableUnavailable=false
        } = executionObject;
        if(!variableName || variableUnavailable){
            return {
                updatedVariableObject : variableObject,
                hasError : true,
                errorPayload : 'Variable name is missing.',
                hasReturnStatement : false,
                returnStatement : undefined
            }
        }
        let variableCopy = variableObject;
        const {inhouz_reserved_parameters_xyzh={}} = parameterObject;
        const {appEnvironment} = inhouz_reserved_parameters_xyzh;
        let parsedValue = appEnvironment && typeof appEnvironment === 'string' ?
        appEnvironment : 'development';
        variableCopy[variableName] = parsedValue;
        return {
            hasReturnStatement : false,
            returnStatement : undefined,
            updatedVariableObject : variableCopy,
            hasError : false,
            errorPayload : undefined
        }
    }catch(e){
        console.log('/utils/functionParser/general/functionTypeParser/base64Parser catch block error', e);
        return {
            updatedVariableObject : variableObject,
            hasError : true,
            errorPayload : e.stack,
            hasReturnStatement : false,
            returnStatement : undefined
        }
    }
}